import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import { useActionCable } from 'use-action-cable';
import Loading from '../search/EventFinder/Loading';
import ConversationListItem from './ConversationListItem';
import { getUpdatedConversations, notEmpty } from './helpers/utils';

import {
  CONVERSATIONS_ENDPOINT,
  CONVERSATIONS_ENDPOINT_PAGED,
} from '../../api';

const CHECK_FOR_UPDATES_TIMER = 1000 * 15;
let currentTimer;

const Conversations = ({ currentConversationId, userId, actionCableOn }) => {
  const [conversations, setConversations] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [lastTimeout, setLastTimeout] = useState(moment());

  const channelHandlers = channelInfo => ({
    connected() {
      console.log('Websocket connected to', channelInfo);
    },
    disconnected() {
      console.log('Websocket disconnected from', channelInfo);
    },
    received: conversation => {
      console.log('Websocket received conversation', {
        ...channelInfo,
        ...conversation,
      });

      setConversations(currentConversations => {
        return getUpdatedConversations(currentConversations, [conversation]);
      });
    },
  });

  if (actionCableOn) {
    useActionCable(
      { channel: 'ConversationsChannel', id: userId },
      channelHandlers({ channel: 'ConversationsChannel', id: userId }),
    );
  }

  // Check every [n] seconds for more conversations
  // Check if new/updated conversation records
  // Return if user has been inactive on the page for [m] minutes
  const checkForUpdatedConversations = async () => {
    const response = await axios.get(
      `${CONVERSATIONS_ENDPOINT(
        lastTimeout.subtract(30, 'seconds').format(),
      )}&pagination=false`,
    );
    const { conversations: newConversations } = response.data.data;

    if (notEmpty(newConversations)) {
      setConversations(currentConversations =>
        getUpdatedConversations(currentConversations, newConversations),
      );
    }

    setLastTimeout(moment());

    clearTimeout(currentTimer);
    currentTimer = setTimeout(
      () => checkForUpdatedConversations(),
      CHECK_FOR_UPDATES_TIMER,
    );
  };

  const loadMoreConversations = async () => {
    await axios.get(CONVERSATIONS_ENDPOINT_PAGED(page)).then(res => {
      const { conversations: newConversations } = res.data.data;

      setConversations(currentConversations => {
        return getUpdatedConversations(currentConversations, newConversations);
      });

      setPage(page + 1);

      setHasMore(
        res.data.meta.pagination.current_page <
          res.data.meta.pagination.total_pages,
      );
    });
  };

  const startTimer = async () => {
    clearTimeout(currentTimer);

    currentTimer = setTimeout(
      () => checkForUpdatedConversations(),
      CHECK_FOR_UPDATES_TIMER,
    );
  };

  if (!actionCableOn) {
    useEffect(() => {
      clearTimeout(currentTimer);
      startTimer();
    }, []);
  }

  return (
    <div className="conversation-index">
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreConversations}
        loader={<Loading key={0} />}
        threshold={250}
        hasMore={hasMore}
      >
        {conversations
          .sort(
            (a, b) =>
              Date.parse(b.last_message_created_at) -
              Date.parse(a.last_message_created_at),
          )
          .map(conversation => (
            <ConversationListItem
              key={conversation.id}
              conversation={conversation}
              currentConversationId={currentConversationId}
            />
          ))}
      </InfiniteScroll>
    </div>
  );
};

export default Conversations;
