import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useActionCable } from 'use-action-cable';
import Scanner from '../scanner/Scanner';
import ScannerBar from './ScannerBar';
import FilterBar from './FilterBar';
import ScanRow from './ScanRow';
import ManualScanModal from './ManualScanModal';
import axios from 'axios';

const HOST_INDEX_ENDPOINT   = (hostId, conId, page, filters) => `/api/v1/hosts/${hostId}/cons/${conId}/scans?page=${page}&filters=${filters}`;
const EVENT_INDEX_ENDPOINT  = (eventId, page, filters) => `/api/v1/events/${eventId}/scans?page=${page}&filters=${filters}`;
const HOST_REDEEM_ENDPOINT  = (hostId, conId) => `/api/v1/hosts/${hostId}/cons/${conId}/scans/redeem`;
const EVENT_REDEEM_ENDPOINT = eventId => `/api/v1/events/${eventId}/scans/redeem`;
const GUEST_REDEEM_ENDPOINT = id => `/api/v1/scans/${id}/guest_redeem`;
const ISSUE_TICKET_ENDPOINT = id => `/api/v1/scans/${id}/issue_ticket`;
const REFRESH_SCAN_ENDPOINT = (id, hostId='') => `/api/v1/scans/${id}?host_id=${hostId}`;
const DISMISS_SCAN_ENDPOINT = id => `/api/v1/scans/${id}`;

const TicketRedemptions = ({ conId, hostId, eventId=0, scanWindowOpen=true, actionCableOn }) => {
  const [focus, setFocus] = useState(document.hasFocus()); // indicates if page has focus / tab is active
  const [filterList, setFilterList] = useState([]); // list of ScanToRedeem states currently filtering by
  const [guestScanning, setGuestScanning] = useState({enabled: false, host:{}}); // controls if guest scanning is active, control with toggleGuestScanning
  const [showSpinner, setShowSpinner] = useState(false); // toggle to show spinner
  const [optionsOpenID, setOptionsOpenID] = useState(null);
  const [hasMore, setHasMore] = useState(true); // indicates if there are more scans to load
  const [currentPage, setCurrentPage] = useState(1); // current page of scans loaded, used for infinite scroll
  const [showManualScan, setShowManualScan] = useState(false); // toggle to show manual scan modal
  const [scansData, setScansData] = useState({
    scans : [],
    scanCounts: {
      "redeemed": 0,
      "partially_redeemed": 0,
      "guest_redeemed": 0,
      "can_issue": 0,
      "can_over_issue": 0,
      "can_comp": 0,
      "can_over_comp": 0,
      "can_sell_or_comp": 0,
      "can_over_sell_or_comp": 0,
      "pending_sale": 0,
      "event_selection": 0,
      "past_event": 0,
      "future_event": 0,
      "ticket_error": 0,
      "event_error": 0,
      "badge_error": 0,
      "approval_pending": 0,
      "approval_denied": 0
    }}); // object to store scan data
  const timerID = useRef(null);
  const hostScanning = eventId === 0;
  const scanQueue = useRef([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      unloadScanQueue();
    }, 500);
  
    return () => clearInterval(intervalId);
  }, []);

  const calculateNewScanCounts = (incoming, existing, counts) => {
    if (existing) {
      if (existing.state !== incoming.state) {
        return {...counts, [existing.state]: Math.max(counts[existing.state] - 1,0), [incoming.state]: counts[incoming.state] + 1};
      } else {
        return counts;
      }
    } else {
      return {...counts, [incoming.state]: counts[incoming.state] + 1};
    }
  }

  const unloadScanQueue = () => {
    if (scanQueue.current.length > 0) {
      scanQueue.current.forEach(incoming => {
        setScansData((prev) => {
          let scans = prev.scans;
          let scanCounts = prev.scanCounts;

          let existing = scans.find(s => s.id === incoming.id);
          scanCounts = calculateNewScanCounts(incoming, existing, scanCounts);

          // incoming scan is being dismissed
          if (incoming.hidden) {
            scanCounts = {...scanCounts, [incoming.state]: Math.max(scanCounts[incoming.state] - 1,0)};
            scans = scans.filter(s => s.id !== incoming.id);

            return {scans: scans, scanCounts: scanCounts};
          }

          // incoming scan is not visible in current filters
          if (filterList.length > 0 && !filterList.includes(incoming.state)) {
            return {scans: scans, scanCounts: scanCounts};  
          }

          // incoming scan already exists in the list and not changed
          if (existing && existing.updated_at === incoming.updated_at) {
            return {scans: scans, scanCounts: scanCounts};
          }

          // incoming scans are inserted 3 from the top to avoid misclicks
          if (scans.length < 4) {
            return {scans: [...scans.filter(s => s.id !== incoming.id), incoming], scanCounts: scanCounts};
          } else {
            return {scans: [...scans.slice(0, 3).filter(s => s.id !== incoming.id), incoming, ...scans.slice(3).filter(s => s.id !== incoming.id)], scanCounts: scanCounts};
          }
        })
      });
      scanQueue.current = [];
    }
  }


  const channelHandlers = channelInfo => ({
    connected() {
      console.log('Websocket connected to', channelInfo);
    },
    disconnected() {
      console.log('Websocket disconnected from', channelInfo);
    },
    received: scan => {
      console.log('Websocket received message', { ...channelInfo, 'scan': scan });
      scanQueue.current.push(scan);
    },
  });

  if (actionCableOn) {
    if (hostScanning) {
      useActionCable(
        { channel: 'HostScansChannel', id: hostId },
        channelHandlers({ channel: 'HostScansChannel', id: hostId }),
      );
    } else {
      useActionCable(
        { channel: 'EventScansChannel', id: eventId },
        channelHandlers({ channel: 'EventScansChannel', id: eventId }),
      );
    }
  } else {
    useEffect(() => {
      if ((focus || Object.keys(scansData.scanCounts).length === 0) && !guestScanning.enabled) {
        timerID.current = setInterval(fetchRecentScans, 20000);
      } else {
        clearInterval(timerID.current);
      }

      return () => {
        clearInterval(timerID.current);
      };
    }, [focus, guestScanning.enabled, filterList]);
  }

  // fetches the first page of scans for the current filters.
  // adds new scans to the top of the list, and floats any updated existing
  // scans to their correct position. removes any existing scans that don't 
  // match the current filters.
  const fetchRecentScans = async () => {
    let endpoint = hostScanning ? HOST_INDEX_ENDPOINT(hostId, conId, 1, filterList.join(',')) : EVENT_INDEX_ENDPOINT(eventId, 1, filterList.join(','));
    await axios
      .get(endpoint)
      .then(response => {
        let scanIds = response.data.scans.map(scan => scan.id);

        if (filterList.length > 0) {
          setScansData((prev) => {
            return {
              scans: [...response.data.scans, ...prev.scans.filter(scan => (!scanIds.includes(scan.id) && filterList.includes(scan.state)))],
              scanCounts: response.data.meta.state_counts
            }
          })
        } else {
          setScansData((prev) => {
            return {
              scans: [...response.data.scans, ...prev.scans.filter(scan => (!scanIds.includes(scan.id)))],
              scanCounts: response.data.meta.state_counts
            }
          })
        }
        setHasMore(currentPage < response.data.meta.pagination.total_pages)
      })
      .catch(error => {console.log(error)});
  }

  // fetches the next page of scans for the current filters.
  const fetchMoreScans = async () => {
    let endpoint = hostScanning ? HOST_INDEX_ENDPOINT(hostId, conId, currentPage + 1, filterList.join(',')) : EVENT_INDEX_ENDPOINT(eventId, currentPage + 1, filterList.join(','));
    await axios
      .get(endpoint)
      .then(response => {
        let scanIds = response.data.scans.map(scan => scan.id);

        if (filterList.length > 0) {
          setScansData((prev) => {
            return {
              scans: [...prev.scans.filter(scan => (!scanIds.includes(scan.id) && filterList.includes(scan.state))), ...response.data.scans],
              scanCounts: response.data.meta.state_counts
            }
          })
        } else {
          setScansData((prev) => {
            return {
              scans: [...prev.scans.filter(scan => !scanIds.includes(scan.id)), ...response.data.scans],
              scanCounts: response.data.meta.state_counts
            }
          })
        }
        setHasMore(response.data.meta.pagination.page < response.data.meta.pagination.total_pages)
        setCurrentPage(response.data.meta.pagination.page);
      })
      .catch(error => {console.log(error)});
  }

  // whenever filters change, reset the scan list and fetch the first page of scans
  useEffect(() => {
    setCurrentPage(1);
    fetchRecentScans()
  }, [filterList]);

  const dismissScan = async (id) => {
    setShowSpinner(true);
    await axios
      .delete(DISMISS_SCAN_ENDPOINT(id))
      .then(response => {
        if (response.status == 200) {
          setScansData((prev) => {
            let scan = prev.scans.find(scan => scan.id === id);
            if (scan) {
              return {scans: prev.scans.filter(scan => scan.id !== id), scanCounts: {...prev.scanCounts, [scan.state]: Math.max(prev.scanCounts[scan.state] - 1,0)}}
            } else {
              return prev;
            }
          });
        }
      })
      .catch(error => {console.log(error)});
    setShowSpinner(false);
  };

  const issueTicket = async (id, oversell, comp) => {
    setShowSpinner(true);
    await axios
      .post(ISSUE_TICKET_ENDPOINT(id), { oversell: oversell, comp: comp })
      .then(response => {
        setScansData((prev) => {
          let existing = prev.scans.find(scan => scan.id === id);
          return {scans: [response.data.scan, ...prev.scans.filter(scan => scan.id !== id)], scanCounts: calculateNewScanCounts(response.data.scan, existing, prev.scanCounts)}
        });
      })
      .catch(error => {console.log(error)});
    setShowSpinner(false);
  };

  const sortScans = (scans) => {
    return scans.sort((a, b) => new Date(a.updated_at) < new Date(b.updated_at) ? 1 : -1);
  }

  const toggleFilters = (filters) => {
    if (filters.some(filter => filterList.includes(filter))) {
      setFilterList(filterList.filter(filter => !filters.includes(filter)));
    } else {
      setFilterList([...filterList, ...filters]);
    }
  };

  const toggleOptions = async (scan) => {
    let id = scan.id;
    if (id === optionsOpenID) {
      setOptionsOpenID(null);
    } else {
      setOptionsOpenID(id);
      if (!actionCableOn || scan.state === 'event_selection') {
        await axios
          .get(REFRESH_SCAN_ENDPOINT(id, hostId))
          .then(response => {
            setScansData((prev) => {
              let index = prev.scans.findIndex(scan => scan.id === id);
              let existing = prev.scans[index];
              let updated = response.data.scan;
              if (existing.state !== updated.state || existing.additional_tickets !== updated.additional_tickets || scan.state === 'event_selection') {
                return {scans: [...prev.scans.slice(0, index), updated, ...prev.scans.slice(index + 1)], scanCounts: calculateNewScanCounts(updated, existing, prev.scanCounts)}
              }
              return prev;
            });
          })
          .catch(error => {console.log(error)});
      }
    }
  }

  const pendingScan = (badgeCode) => {
    return {badge_code: badgeCode, state: 'pending_response', message: '', ticketholder: {first_name: badgeCode, last_name: ''}, additional_tickets: 0, id: Math.random(), updated_at: new Date()};
  }

  const redeemForEvent = async (id, badgeCode, eventId) => {
    let pending = pendingScan(badgeCode);
    setScansData((prev) => {
      let existing = prev.scans.find(s => s.id === id);
      return {scans: [pending, ...prev.scans.filter(scan => scan.id !== id)], scanCounts: {...prev.scanCounts, [existing.state]: Math.max(prev.scanCounts[existing.state] - 1,0)}};
    });
    await axios
      .post(EVENT_REDEEM_ENDPOINT(eventId), { badge_code: badgeCode, host_id: hostId })
      .then(response => {
        setScansData((prev) => {
          let scan = response.data.scan;
          let existing = prev.scans.find(s => s.id === scan.id);
          return {scans: [response.data.scan, ...prev.scans.filter(scan => scan.id !== response.data.scan.id && scan.id !== pending.id)], scanCounts: calculateNewScanCounts(scan, existing, prev.scanCounts)}
        });
      }).catch(error => {console.log(error)});
  }

  const onScan = async (badgeCode) => {
    let pending = pendingScan(badgeCode);
    setScansData((prev) => ({scans: [pending, ...prev.scans], scanCounts: prev.scanCounts}));
    if (guestScanning.enabled) {
      await axios
        .post(GUEST_REDEEM_ENDPOINT(guestScanning.host.id), { badge_code: badgeCode })
        .then(response => {
          setScansData((prev) => {
            let scan = response.data.scan;
            let existing = prev.scans.find(s => s.id === scan.id);
            let scanCounts = calculateNewScanCounts(scan, existing, prev.scanCounts);
            let guestScan = response.data.guest_scan;
            let existingGuest = prev.scans.find(s => s.id === guestScan.id);
            scanCounts = calculateNewScanCounts(guestScan, existingGuest, scanCounts);
            return {scans: [response.data.scan, response.data.guest_scan, ...prev.scans.filter(scan => scan.id !== response.data.guest_scan.id && scan.id !== response.data.scan.id && scan.id !== pending.id)], scanCounts: scanCounts}
          });
          if (response.data.scan.additional_tickets === 0) {
            if (optionsOpenID === guestScanning.host.id) {
              setOptionsOpenID(null);
            }
            setGuestScanning({enabled:false, host: {}});
          } else {
            setGuestScanning({enabled:true, host: response.data.scan});
          }
        }).catch(error => {console.log(error); setScansData((prev) => ({scans: prev.scans.filter(scan => scan.id !== pending.id), scanCounts: prev.scanCounts}))});
    } else {
      let endpoint = hostScanning ? HOST_REDEEM_ENDPOINT(hostId, conId) : EVENT_REDEEM_ENDPOINT(eventId);
      await axios
        .post(endpoint, { badge_code: badgeCode })
        .then(response => {
          setScansData((prev) => {
            let scan = response.data.scan;
            let existing = prev.scans.find(s => s.id === scan.id);
            return {scans: [response.data.scan, ...prev.scans.filter(scan => scan.id !== response.data.scan.id && scan.id !== pending.id)], scanCounts: calculateNewScanCounts(scan, existing, prev.scanCounts)}
          });
        }).catch(error => {console.log(error); setScansData((prev) => ({scans: prev.scans.filter(scan => scan.id !== pending.id), scanCounts: prev.scanCounts}))});
    }
  }

  const toggleGuestScanning = (id) => {
    if (guestScanning.enabled && id === guestScanning.host.id) {
      setGuestScanning({enabled: false, host: {}});
      if (optionsOpenID === id) {
        setOptionsOpenID(null);
      }
    } else {
      let hostScan = scansData.scans.find(scan => scan.id === id);
      setGuestScanning({enabled: true, host: hostScan});
      setScansData((prev) => ({scans: [hostScan, ...sortScans(prev.scans).filter(scan => scan.id !== hostScan.id)], scanCounts: prev.scanCounts}));
    }
  }

  return (
    <div className='redemptions-container'>
      {
        showManualScan && <div className='content' style={{padding: 0}}>
          <ManualScanModal scan={onScan} close={() => setShowManualScan(false)}/>
        </div>
      }
      {scanWindowOpen && <ScannerBar focus={focus} guestScanning={guestScanning} setShowManualScan={setShowManualScan} />}
      {scanWindowOpen && <Scanner onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} onScan={onScan} pattern={'^[B,b]-[A-Za-z0-9]{12}$'} timeout={250}/>}
      <FilterBar
        scanCounts={scansData.scanCounts}
        filterList={filterList}
        toggleFilters={toggleFilters}
      />
      <InfiniteScroll
        dataLength={scansData.scans.length}
        next={fetchMoreScans}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
      >
        <div>
          <table className="scan-ticket-table">
            <tbody style={{columnCount: 5}}>
              {scansData.scans.map((scan) =>
                <ScanRow
                  key={scan.id}
                  scan={scan}
                  optionsOpenID={optionsOpenID}
                  toggleOptions={toggleOptions}
                  showSpinner={showSpinner}
                  dismissScan={dismissScan}
                  issueTicket={issueTicket}
                  hostScanning={hostScanning}
                  guestScanning={guestScanning}
                  toggleGuestScanning={toggleGuestScanning}
                  redeemForEvent={redeemForEvent}
                />
              )}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default TicketRedemptions;