import React from 'react';
import { ActionCableProvider } from 'use-action-cable';
import Messages from './Messages';

const MessagesWrapper = ({
  eventId,
  userId,
  userCanMute,
  userIsEventManager,
  actionCableOn,
}) => {
  return (
    <ActionCableProvider url="/cable">
      <Messages
        eventId={eventId}
        userId={userId}
        userCanMute={userCanMute}
        actionCableOn={actionCableOn}
        userIsEventManager={userIsEventManager}
      />
    </ActionCableProvider>
  );
};

export default MessagesWrapper;
