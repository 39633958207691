import { Controller } from 'stimulus';

const ENTER_DATA_PREFIX = 'new-payment-';
const EDIT_DATA_PREFIX = 'edit-payment-';
const ENTER_PAYMENT_SELECTOR = id => `.enter-payment[data-id="${id}"]`;
const EDIT_SELECTOR = id => `.edit-rec[data-id="${id}"]`;

export default class extends Controller {
  pay(event) {
    event.preventDefault();

    const { target } = event;
    const table = target.closest('table');
    const selected = target.value.trim();

    if (selected === 'Wire Transfer') {
      this.hideElements('.rec-check', table);
      this.hideElements('.rec-credit', table);

      this.showElements('.rec-wt', table);
      this.showElements('.rec-notes', table);
    }

    if (selected === 'Check') {
      this.hideElements('.rec-wt', table);
      this.hideElements('.rec-credit', table);

      this.showElements('.rec-check', table);
      this.showElements('.rec-notes', table);
    }

    if (selected === 'Credit Card') {
      this.hideElements('.rec-wt', table);
      this.hideElements('.rec-check', table);
      this.hideElements('.rec-notes', table);

      this.showElements('.rec-credit', table);
    }
  }

  enterPayment(event) {
    event.preventDefault();

    const { target } = event;
    const { id } = target.dataset;
    const cssSelector = `[data-target="${ENTER_DATA_PREFIX}${id}"]`;
    const selector = document.querySelector(cssSelector);

    selector.classList.remove('hide');
    target.classList.add('hide');
  }

  cancelPayment(event) {
    event.preventDefault();

    const { target } = event;
    const { id } = target.dataset;
    const linkSelector = ENTER_PAYMENT_SELECTOR(id);
    const link = document.querySelector(linkSelector);
    const cssSelector = `[data-target="${ENTER_DATA_PREFIX}${id}"]`;
    const selector = document.querySelector(cssSelector);

    link.classList.remove('hide');
    selector.classList.add('hide');
  }

  edit(event) {
    event.preventDefault();

    const { target } = event;
    const { id } = target.dataset;
    const cssSelector = `[data-target="${EDIT_DATA_PREFIX}${id}"]`;
    const selector = document.querySelector(cssSelector);

    selector.classList.remove('hide');
    target.classList.add('hide');
  }

  cancelEdit(event) {
    event.preventDefault();

    const { target } = event;
    const { id } = target.dataset;
    const linkSelector = EDIT_SELECTOR(id);
    const link = document.querySelector(linkSelector);
    const cssSelector = `[data-target="${EDIT_DATA_PREFIX}${id}"]`;
    const selector = document.querySelector(cssSelector);

    link.classList.remove('hide');
    selector.classList.add('hide');
  }

  showElements(element, table) {
    const fields = table.querySelectorAll(element);
    fields.forEach(i => (i.style.display = 'table-row'));
  }

  hideElements(element, table) {
    const fields = table.querySelectorAll(element);
    fields.forEach(i => (i.style.display = 'none'));
  }
}
