import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-modal';
import { replace, map } from 'ramda';
import Loading from './Loading';
import SearchFilterBadge from './SearchFilterBadge';
import SearchFilterCheckboxes from './SearchFilterCheckboxes';
import SearchFilterSelector from './SearchFilterSelector';
import axios from 'axios';
import { filter, without, equals } from 'ramda';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100000,
    bottom: 0,
    backgroundColor: 'rgba(10, 10, 10, 0.60)',
  },
  content: {
    border: 'solid #bca98b 1px',
    background: '#f4f1ec',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '7px',
    outline: 'none',
    padding: '0',
    overflow: 'hidden',
  },
};

class SearchFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      selectorOpen: true,
      groupSelected: '',
      checkboxesOpen: false,
      metaData: [],
      selectedGroup: [],
      searchFilter: props.searchFilter,
      loading: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateModalContent = this.updateModalContent.bind(this);
    this.handleBadgeRemoval = this.handleBadgeRemoval.bind(this);
    this.handleBadgeAdd = this.handleBadgeAdd.bind(this);
  }

  loadMetaData(innerSelect, groupSelected) {
    const formattedUrl = replace(/game\[\]=\+/g, 'game[]=%2B')(this.props.searchQuery)
    let metaDataUrl =
      '/api/event_search/meta_data' + `?${formattedUrl}`;
    if (groupSelected) {
      metaDataUrl = metaDataUrl + `&filter=${groupSelected}`;
    }
    if (!this.state.loading) {
      this.setState({ loading: true });
      return axios.get(metaDataUrl).then(res => {
        let notEmpty = item => item['key'] !== '';
        let filteredBuckets = filter(
          notEmpty,
          res.data.filtered[innerSelect].buckets,
        );
        this.setState({ loading: false, metaData: filteredBuckets });
        return filteredBuckets;
      });
    }
  }

  openModal() {
    this.setState({
      modalIsOpen: true,
      selectorOpen: true,
      checkboxesOpen: false,
    });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.props.onChange(this.state.searchFilter);
  }

  updateModalContent(selected, groupSelected) {
    let innerSelect =
      selected == 'group_sponsor' ? 'printable_group_sponsor' : selected;
    let promise = this.loadMetaData(innerSelect, groupSelected);
    promise.then(filteredMetaData => {
      this.setState({
        selectorOpen: false,
        checkboxesOpen: true,
        groupSelected: groupSelected,
        selectedGroup: filteredMetaData,
      });
    });
  }

  selectModal() {
    if (this.state.selectorOpen && !this.state.loading) {
      return (
        <SearchFilterSelector
          searchFilter={this.state.searchFilter}
          updateModalContent={this.updateModalContent}
          closeModal={this.closeModal}
        />
      );
    } else if (this.state.selectorOpen && this.state.loading) {
      return <Loading />;
    } else {
      return (
        <SearchFilterCheckboxes
          searchFilter={this.state.searchFilter}
          dayMapping={this.props.dayMapping}
          groupSelected={this.state.groupSelected}
          selectedGroup={this.state.selectedGroup}
          onSelect={this.handleBadgeAdd}
          closeModal={this.closeModal}
        />
      );
    }
  }

  handleBadgeAdd(group, name) {}

  handleBadgeRemoval(group, name) {
    let searchFilter = this.state.searchFilter;
    let newGroup

    console.log({searchFilter})

    if (group === 'game') {
      const games = map(replace(/^\s/, '+'))(searchFilter[group])
      console.log({games})

      newGroup = without(String(name), games);
      console.log({games, group, name, newGroup})

    }else{
      newGroup = without(String(name), searchFilter[group]);
    }
    searchFilter[group] = newGroup;
    this.props.onChange(searchFilter);
  }

  isGroupChecked(key) {
    if (this.state.searchFilter[key].length > 0)
      return true
    else {
      return false
    }
  }

  render() {
    let filter = this.props.searchFilter;
    let badges = [];
    for (let group in filter) {
      let groupBadges = filter[group].map(name => {
        const formattedName = name.replace(/^\s/, '+')
        if (name) {
          badges.push(
            <SearchFilterBadge
              dayMapping={this.props.dayMapping}
              key={`${group}-${name}`}
              group={group}
              name={formattedName}
              onClick={this.handleBadgeRemoval}
            />,
          );
        }
      });
    }

    return (
      <div className="finder-filters">
        <div className="finder-filter-link">{badges}</div>

        {/* <button
          onClick={this.openModal}
          className="search-filter button"
          title="Filter Search"
        >
          <i className="fa fa-filter fa-2x"></i>
        </button> */}

        <div className="dash-buttons " style={{width: '98%', marginLeft: 'auto', marginRight: 'auto'}}>
          <div  className="dash-button">
            <div onClick={() => {this.updateModalContent('event_type', 'category'); this.openModal()}}
            className={classNames({ 'modal-filter-checkbox flex-v-center flex-space-between': true, 'checked': this.isGroupChecked('category') })}>
              <div className='flex-v-center'>
                <i className="fa fa-events_CardGames"></i>
                {" Event Type"}
              </div>
            </div>
          </div>
          <div  className="dash-button">
            <div onClick={() => {this.updateModalContent('game_system', 'game'); this.openModal()}}
            className={classNames({ 'modal-filter-checkbox flex-v-center flex-space-between': true, 'checked': this.isGroupChecked('game') })}>
              <div className='flex-v-center'>
                <i className="fa fa-events_RPG"></i>
                {" Game System"}
              </div>
            </div>
            </div>
          <div  className="dash-button">
            <div onClick={() => {this.updateModalContent('start_day', 'day'); this.openModal()}}
            className={classNames({ 'modal-filter-checkbox flex-v-center flex-space-between': true, 'checked': this.isGroupChecked('day') })}>
              <div className='flex-v-center'>
                <i className="fa fa-calendar"></i>
                {" Day"}
              </div>
            </div>
            </div>
          <div  className="dash-button">
            <div onClick={() => {this.updateModalContent('start_hour', 'hour'); this.openModal()}}
            className={classNames({ 'modal-filter-checkbox flex-v-center flex-space-between': true, 'checked': this.isGroupChecked('hour') })}>
              <div className='flex-v-center'>
                <i className="fa fa-clock-o"></i>
                {" Start Time"}
              </div>
            </div>
          </div>
          <div  className="dash-button">
            <div onClick={() => {this.updateModalContent('group_sponsor', 'host'); this.openModal()}}
            className={classNames({ 'modal-filter-checkbox flex-v-center flex-space-between': true, 'checked': this.isGroupChecked('host') })}>
              <div className='flex-v-center'>
                <i className="fa fa-building"></i>
                {" Company / Group"}
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {this.selectModal()}
        </Modal>
      </div>
    );
  }
}

export default SearchFilter;
