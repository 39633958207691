import Flatpickr from 'stimulus-flatpickr';

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  connect() {
    //define locale and global flatpickr settings for all date pickers
    this.config =
      {
        allowInput:  true,
        altFormat:   'F d, Y h:i K',
        altInput:    true,
        dateFormat:  'Y/m/d h:i K',
        defaultDate: this.data.get('defaultDate'),
        enableTime:  true,
        maxDate:     this.data.get('maxDate'),
        minDate:     this.data.get('minDate'),
        time_24hr:   false
      };

    super.connect();
  }

  // automatically submit form when a date is selected
  change(selectedDates, dateStr, instance) {
    const form = this.element.closest('form');
  }
}
