import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Loading from '../search/EventFinder/Loading';
import EventRegistrations from './EventRegistrations';
import SearchAndFilters from './SearchAndFilters';
import BulkGmUpdater from './BulkGmUpdater';
import ReactPaginate from 'react-paginate';

const EventRegistrationsContainer = ({
  canBulkUpdate,
  canCreateETicketingRequest,
  conventionId,
  currentConventionName,
  hostPolicyLink,
  isAdmin,
  userId,
  training_complete,
  isEventHost,
  isEticketingMaster,
  conventionInitials,
}) => {
  const EVENT_REGISTRATION_SEARCH_ENDPOINT =
    '/api/v2/event_registration_search/search';
  const CSRF_ENDPOINT = `/api/v1/sessions/csrf`;
  const [eventRegistrations, setEventRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtersPresent, setFiltersPresent] = useState(false);
  const [eventIds, setEventIds] = useState([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [gmUpdateShown, setGmUpdateShown] = useState(false);
  const [filterChoices, setFilterChoices] = useState({
    status: [],
    type: [],
    gameSystem: [],
    rulesEdition: [],
    gamemasters: [],
    date: [],
    time: [],
    ticketingMethod: [],
    price: [],
    query: [],
  });

  const searchFiltersRef = useRef({
    status: [],
    type: [],
    gameSystem: [],
    rulesEdition: [],
    gamemasters: [],
    date: [],
    time: [],
    ticketingMethod: [],
    price: [],
    query: [],
  });

  const getQuery = () => {
    let searchQuery = {};
    let _filtersPresent = false;
    Object.keys(searchFiltersRef.current).forEach(key => {
      searchQuery[key] = searchFiltersRef.current[key].map(i => i.value);
      if (searchQuery[key].length > 0) _filtersPresent = true;
    });
    setFiltersPresent(_filtersPresent);
    searchQuery['userId'] = userId;
    searchQuery['page'] = currPage;
    return searchQuery;
  };

  const updateEvents = () => {
    setEventRegistrations([]);
    fetchEvents();
  };

  const noEventsToRender = () => {
    if (loading) {
      return <Loading />;
    } else {
      return (
        <p>
          {
            'You have no events for the selected convention and filters. Please read the '
          }
          <a href={hostPolicyLink}>Event Host Policy</a>
          {' for information about hosting events.'}
        </p>
      );
    }
  };

  const fetchEvents = async () => {
    let query = getQuery();
    setLoading(true);
    await axios
      .get(EVENT_REGISTRATION_SEARCH_ENDPOINT, { params: query })
      .then(res => {
        setEventRegistrations(res.data.groupedEvents);
        setEventIds(res.data.eventIds);
        setFilterChoices(res.data.filterChoices);
        setPageCount(res.data.pagination.totalPages);
        setTotalEvents(res.data.pagination.totalEvents);
        setPerPage(res.data.pagination.perPage);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);

        // Redirect to login page if session has expired
        window.location = '/?expired=true';
      });
  };

  useEffect(() => {
    const initialFetch = async () => {
      await axios.get(CSRF_ENDPOINT).then(res => {
        updateEvents();
      });
    };
    initialFetch();
  }, []);

  useEffect(() => {
    const fetchNextPage = async () => {
      let query = getQuery();
      setLoading(true);
      await axios
        .get(EVENT_REGISTRATION_SEARCH_ENDPOINT, { params: query })
        .then(res => {
          setEventRegistrations(res.data.groupedEvents);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
        });
    };
    if (currPage != 0) {
      fetchNextPage();
    }
  }, [currPage]);

  const toggleGmUpdate = event => {
    event.preventDefault();
    if (gmUpdateShown) {
      fetchEvents();
    }
    setGmUpdateShown(!gmUpdateShown);
  };

  const getExportLink = () => {
    return filtersPresent
      ? `/export_event_submissions/${userId}?event_ids=${eventIds}`
      : `/export_event_submissions/${userId}?convention_id=${conventionId}`;
  };

  const getExportName = () => {
    return filtersPresent ? 'Export Filtered Events' : 'Export All Events';
  };

  return (
    <div>
      {gmUpdateShown ? (
        <div>
          <div className="ribbon-top">
            <ul className="left">
              <li className="btn" onClick={toggleGmUpdate}>
                <a href="#">
                  <i className="fa fa-arrow-left fa-2x"></i>
                  <label>Event Registrations</label>
                </a>
              </li>
            </ul>
          </div>
          <BulkGmUpdater
            searchFiltersRef={searchFiltersRef}
            eventIds={eventIds}
            gamemasters={filterChoices.gamemasters}
          />
        </div>
      ) : (
        <div>
          <div className="ribbon-top">
            <ul className="left">
              <li className="btn">
                <a href={`/event_registrations/new?contact_id=${userId}`} title="New Event">
                  <i className="fa fa-plus-circle fa-2x"></i>
                  <label class="hide-for-small">Submit a New Event</label>
                </a>

              </li>
              {canBulkUpdate && (
                <li className="btn" onClick={toggleGmUpdate}>
                  <a href="#" title="Submit a New Event">
                    <i className="fa fa-report-upload fa-2x"></i>
                    <label class="hide-for-small" title="GM Bulk Update">GM Bulk Update</label>
                  </a>
                </li>
              )}
              <li className="btn">
                <a href={getExportLink()} title={getExportName()}>
                  <i className="fa fa-report-search-export fa-2x"></i>
                  <label className="hide-for-small">{getExportName()}</label>
                </a>
              </li>
              <li className="btn">
                <a
                  href={`/historical_event_submissions/${userId}`}
                  title="View Historical Events"
                >
                  <i className="fa fa-history fa-2x"></i>
                  <label class="hide-for-small">View Past Events</label>
                </a>
              </li>
              <li className="has-sub btn">
                <a href="#">
                  <i className="fa fa-ticket-e fa-2x"></i>
                  <label class="hide-for-small">E-ticketing</label>
                </a>
                <ul>
                  {(canCreateETicketingRequest && <li><a href={`/users/e-ticketing-request`}>Request</a></li>)}
                  {(isEventHost && <li><a href={`/schedules/${userId}/host_scanning`}>Scan For All My Events</a></li>)}
                  {(isEticketingMaster && <li><a href={`/con/${conventionInitials}/host_scanning`}>Scan For Host</a></li>)}
                  <li><a href={'/e-ticketing/training'}>Training</a></li>
                  {(training_complete && <li><a href="/e-ticketing/training?task=sandbox">Sandbox</a></li>)}
                  
                </ul>
              </li>
            </ul>
          </div>

          <p>
            {
              'By submitting an event, you attest that you have read and agree to abide by everything listed in the '
            }
            <a href={hostPolicyLink}>Event Host Policy.</a>
          </p>
          <SearchAndFilters
            searchFiltersRef={searchFiltersRef}
            updateEvents={updateEvents}
            filterChoices={filterChoices}
          />

          {eventRegistrations.length > 0 && (
            <div className="right">
              <div className="paging-info">
                {'Displaying '}
                <b>
                  {Math.max((currPage - 1) * perPage, 1)}
                  {' - '}
                  {Math.min(
                    Math.max(currPage - 1, 0) * perPage + perPage,
                    totalEvents,
                  )}
                </b>
                {' out of '}
                <b>{totalEvents}</b>
                {' Event Registrations'}
              </div>
              <div className="paginate">
                <ReactPaginate
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={page => {
                    if (currPage != page.selected + 1)
                      setCurrPage(page.selected + 1);
                  }}
                  containerClassName={'react-paginate'}
                  activeClassName={'page-active'}
                />
              </div>
            </div>
          )}

          {eventRegistrations.length == 0 ? (
            noEventsToRender()
          ) : (
            <EventRegistrations
              eventRegistrations={eventRegistrations}
              isAdmin={isAdmin}
              userId={userId}
            />
          )}
        </div>
      )}
      <div className="ribbon-bottom"></div>
    </div>
  );
};

export default EventRegistrationsContainer;
