import React from 'react';
import { ActionCableProvider } from 'use-action-cable';
import Conversations from './Conversations';

const ConversationsWrapper = ({ currentConversationId, userId }) => {
  return (
    <ActionCableProvider url="/cable">
      <Conversations
        currentConversationId={currentConversationId}
        userId={userId}
      />
    </ActionCableProvider>
  );
};

export default ConversationsWrapper;
