import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Switch } from 'react-router-dom';
import { noticeMsg, errorMsg } from '../../helpers/notifications';
import PropsRoute from './../PropsRoute';
import SatelliteConventionAttendeeList from './SatelliteConventionAttendeeList';
import SatelliteConventionAttendeeForm from './SatelliteConventionAttendeeForm';

class SatelliteConventionAttendeeManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      satelliteConvention: props.satelliteConvention,
      satelliteConventionAttendees: null,
      admin: props.admin,
    };

    this.createSatelliteConventionAttendee = this.createSatelliteConventionAttendee.bind(
      this,
    );
    this.updateSatelliteConventionAttendee = this.updateSatelliteConventionAttendee.bind(
      this,
    );
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  componentDidMount() {
    const { satelliteConvention } = this.state;

    axios
      .get(
        `/api/v1/satellite_conventions/${
          satelliteConvention.id
        }/satellite_convention_attendees`,
      )
      .then(response =>
        this.setState({
          satelliteConventionAttendees:
            response.data.data.satellite_convention_attendees,
        }),
      )
      .catch(error => {
        console.log(error);
      });
  }

  createSatelliteConventionAttendee(newSatelliteConventionAttendee) {
    const { satelliteConvention } = this.state;

    axios
      .post(
        `/api/v1/satellite_conventions/${
          satelliteConvention.id
        }/satellite_convention_attendees`,
        newSatelliteConventionAttendee,
      )
      .then(response => {
        noticeMsg('Satellite Convention Attendee Added!');
        const savedSatelliteConventionAttendee =
          response.data.data.satellite_convention_attendee;
        this.setState(prevState => ({
          satelliteConventionAttendees: [
            ...prevState.satelliteConventionAttendees,
            savedSatelliteConventionAttendee,
          ],
        }));
        const { history } = this.props;
        history.push(`/satellite_conventions/${satelliteConvention.id}`);
      })
      .catch(error => {
        console.log(error);
        let errorMessages = '';
        error.response.data.messages.errors.forEach(element => {
          if (element.fields !== undefined) {
            element.fields.forEach(field => {
              errorMessages += field.messages.join();
            });
          }
          if (element.message !== undefined) {
            errorMessages += element.message;
          }
        });
        errorMsg(errorMessages);
        window.scrollTo(0, 0);
      });
  }

  updateSatelliteConventionAttendee(updatedSatelliteConventionAttendee) {
    const { satelliteConvention } = this.state;

    axios
      .put(
        `/api/v1/satellite_conventions/${
          satelliteConvention.id
        }/satellite_convention_attendees/${
          updatedSatelliteConventionAttendee.id
        }`,
        updatedSatelliteConventionAttendee,
      )
      .then(() => {
        noticeMsg('Satellite Convention Attendee updated');

        const { satelliteConventionAttendees } = this.state;
        const idx = satelliteConventionAttendees.findIndex(
          satelliteConventionAttendee =>
            satelliteConventionAttendee.id ===
            updatedSatelliteConventionAttendee.id,
        );
        satelliteConventionAttendees[idx] = updatedSatelliteConventionAttendee;
        this.setState({ satelliteConventionAttendees });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleStatusChange(updatedSatelliteConventionAttendee, event) {
    event.preventDefault();
    updatedSatelliteConventionAttendee.status = 'Attended';

    this.updateSatelliteConventionAttendee(updatedSatelliteConventionAttendee);
  }

  render() {
    const { satelliteConvention } = this.props;
    const { satelliteConventionAttendees } = this.state;
    const { admin } = this.state;
    if (satelliteConventionAttendees === null) return null;

    return (
      <div>
        <Switch>
          <PropsRoute
            path="/satellite_conventions/:id/satellite_convention_attendees/new"
            component={SatelliteConventionAttendeeForm}
            satelliteConvention={satelliteConvention}
            onSubmit={this.createSatelliteConventionAttendee}
          />

          <PropsRoute
            path="/satellite_conventions/:id"
            component={SatelliteConventionAttendeeList}
            satelliteConvention={satelliteConvention}
            satelliteConventionAttendees={satelliteConventionAttendees}
            onStatusChange={this.handleStatusChange}
            admin={admin}
          />
        </Switch>
      </div>
    );
  }
}

SatelliteConventionAttendeeManager.propTypes = {
  satelliteConvention: PropTypes.shape().isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  admin: PropTypes.bool.isRequired,
};

export default SatelliteConventionAttendeeManager;
