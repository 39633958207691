import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { popupHeaderStyle, filterMapping } from '../helpers/displayHelpers';

const ScheduleGroupSelector = ({ closeModal, filters, getMetadata }) => {
  const isGroupChecked = key => {
    return filters[key] && filters[key].length > 0;
  };

  return (
    <div>
      <div className="event-finder-modal-title">
        <h2 style={popupHeaderStyle}>Schedule Filters</h2>
      </div>

      {filterMapping.map(filter => {
        const { key, display } = filter;
        const className = classNames('modal-filter-checkbox', {
          checked: isGroupChecked(key),
        });

        /* eslint-disable */
        return (
          <div
            role="button"
            key={key}
            tabIndex={0}
            className={className}
            onClick={() => getMetadata(key)}
          >
            {display}
          </div>
        );
      })}

      <div className="event-filter-close">
        <button type="button" onClick={closeModal} className=" button">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ScheduleGroupSelector;

ScheduleGroupSelector.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getMetadata: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};
