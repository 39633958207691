import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PropsRoute from '../PropsRoute';
import PaymentMethodsPage from './PaymentMethodsPage';
import PaymentMethodPage from './PaymentMethodPage';

class PaymentMethods extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <PropsRoute
              exact
              path="/contacts/:contact_id/payment_methods"
              component={PaymentMethodsPage}
              admin={this.props.admin}
              displayNew={this.props.displayNew}
            />
            <PropsRoute
              path="/contacts/:contact_id/payment_methods/:id"
              component={PaymentMethodPage}
              admin={this.props.admin}
              displayNew={this.props.displayNew}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default PaymentMethods;
