import React, { useEffect, useRef } from "react";
import DemoTicketRedemptions from "../ticketRedemptions/DemoTicketRedemptions";
import Tippy from '@tippyjs/react';

export default function ScanningTooltipView({
  title,
  position,
  direction,
  content,
  onClick,
  buttonContent,
  altButtonText,
  altOnClick,
  scans,
  setScans,
  contentKey,
  setContentKey,
  completeTask,
  scanningDisabled=true,
  raw
}) {
  const tooltipAnchorRef = useRef(null);

  useEffect(() => {
    if (tooltipAnchorRef.current) {
      tooltipAnchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center'  });
    }
  }, []);

  return (
    <div className='content'>
      <h2 className='slide-title'>{title}</h2>
      <div className='slide-tooltip-container'>
        <DemoTicketRedemptions
          scans={scans}
          setScans={setScans}
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scanningDisabled={scanningDisabled}
        />
        <Tippy
          content={
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {raw ? raw : <p className='tooltip-content-p'>{content}</p>}
              <div className='next-button-row' style={{width: '100%', display: 'flex', justifyContent: altOnClick ? 'space-between' : 'end'}}>
                {(altButtonText && <div className='banner-button back-button' onClick={altOnClick}>{altButtonText}</div>)}
                {(buttonContent && <div className='banner-button next-button' onClick={onClick}>{buttonContent}</div>)}
              </div>
            </div>
          }
          visible={true} hideOnClick={false} interactive={true} placement={direction} trigger={'manual'}
          popperOptions={{
            strategy: 'fixed',
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['left', 'right'],
                },
              },
            ],
          }}
        >
          <div className="tooltip-anchor" style={{ ...position }} ref={tooltipAnchorRef}></div>
        </Tippy>
      </div>
    </div>
  );
}