import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['refundPaymentMethod', 'refundForm'];

  submitTransaction(event) {
    event.preventDefault();

    const paymentMethod = this.refundPaymentMethodTarget.value;
    const button = event.target;

    if (paymentMethod === 'System Credit') {
      const response = confirm(
        'System credit is non-refundable, non-transferrable and does not expire. Are you SURE you want to refund to system credit?',
      );

      if (response) {
        this.submitForm(button);
      } else {
        return false;
      }
    } else {
      this.submitForm(button);
    }
  }

  submitForm(button, newUrl) {
    let refundForm = this.refundFormTarget;

    button.value = 'One Moment, Processing...';
    button.setAttribute('disabled', 'disabled');

    if (newUrl) {
      refundForm.action = newUrl;
    }
    refundForm.submit();
  }
}
