import { Controller } from 'stimulus';
import axios from 'axios';
import { path } from 'ramda';
import serialize from '../helpers/serializeForm';

export default class extends Controller {
  static targets = ['cancelLink', 'contactName', 'form', 'submitButton'];

  clearError() {
    const errorElement = document.querySelector('.error');
    errorElement.classList.add('hide');

    if (errorElement) {
      errorElement.innerHTML = '';
    }
  }

  addErrors(errors) {
    if (errors) {
      const errorElement = document.querySelector('.error');
      errorElement.classList.remove('hide');
      let errorHTML = '';

      errors.forEach(({ message }) => {
        errorHTML += `${message}</br>`;
      });

      errorElement.innerHTML = errorHTML;

      this.updateButtonToActive();
    }
  }

  updateButtonToProcessing() {
    const button = this.submitButtonTarget;

    button.value = 'One moment processing...';
    button.classList.add('processing_button');
    button.setAttribute('disabled', 'disabled');
  }

  updateButtonToActive() {
    const button = this.submitButtonTarget;

    button.value = 'Issue Ticket';
    button.classList.remove('processing_button');
    button.removeAttribute('disabled');
  }

  gatherFormData() {
    const form = this.formTarget;
    const cancelLink = this.cancelLinkTarget;
    const contactName = this.contactNameTarget;

    const actionUrl = form.action;
    const data = new FormData(form);
    const formData = serialize(data);
    const successURL = cancelLink.href;
    const name = contactName.value;

    return {
      actionUrl,
      formData,
      successURL,
      contactName: name,
    };
  }

  async submit(event) {
    event.preventDefault();

    this.clearError();
    this.updateButtonToProcessing();
    const {
      actionUrl,
      formData,
      successURL,
      contactName,
    } = this.gatherFormData();

    await axios
      .post(actionUrl, formData)
      .then(response => {
        const urlParams = '?contact_name=' + encodeURIComponent(contactName);

        window.location.href = `${successURL}${urlParams}`;
      })
      .catch(error => {
        if (error.response) {
          const errors = path(['data', 'messages', 'errors'])(error.response);

          this.addErrors(errors);
        }
      });
  }
}
