/* global window  */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ScheduleTable from './ScheduleTable';
import ScheduleFilter from './filters/ScheduleFilter';
import ScheduleSearchBar from './ScheduleSearchBar';
import { fetchSchedules, scheduleIndexStatusSchedule } from './helpers/api';
import {
  urlParamsToCleanQuery,
  replaceUrlState,
  initialSearchValues,
  fetchSearch,
  filterDupeEvents,
  cleanFilters,
  filterEmptySearch,
  removeFilterObject,
} from './helpers/searchHelpers';
import debounce from '../../helpers/debounce';

const Schedule = ({ contactId, c }) => {
  const [searchValues, setSearchValues] = useState(initialSearchValues);
  const [searchAndFilterEnabled, setSearchAndFilterEnabled] = useState(false);

  const updateSearchValues = newOptions => {
    setSearchValues({ ...searchValues, ...newOptions });
  };

  useEffect(() => {
    let intervalId = null;

    const checkScheduleIndexStatus = async () => {
      try {
        const res = await scheduleIndexStatusSchedule({ contactId });
        setSearchAndFilterEnabled(res.data.status);

        if (res.data.status) {
          // If status is true, clear the interval
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Immediately invoke the function
    checkScheduleIndexStatus();

    // Then set up the interval
    intervalId = setInterval(checkScheduleIndexStatus, 5000); // Check every 5 seconds

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  const getTickets = async () => {
    const { page, filters, scheduleData } = searchValues;
    const res = await fetchSchedules({ contactId, page, filters });

    if (res.status === 202) {
      // Retry getTickets after 5 seconds
      setTimeout(getTickets, 5000);
    } else {
      /* eslint-disable camelcase */
      const { data, page: currentPage, has_more: hasMore } = res.data;

      if (data) {
        // Scroll the page to the pageYOffset before we add new elements
        // This allow new infinite scroll elements to push down
        // data.length (50 * 20 = 1000)
        window.scroll(0, window.pageYOffset - data.length * 20);

        updateSearchValues({
          hasMore,
          complete: true,
          scheduleData: filterDupeEvents([...scheduleData, ...data]),
          page: currentPage + 1,
        });
      }
    }
  };

  const updateFilters = newFilters => {
    const { filters } = searchValues;

    const combinedFilters = { ...filters, ...newFilters };
    const query = urlParamsToCleanQuery(filterEmptySearch(combinedFilters));

    if (query === '') {
      replaceUrlState(window.location.pathname);
    } else {
      replaceUrlState(`${window.location.pathname}?${query}`);
    }

    updateSearchValues({
      filters: combinedFilters,
      page: 1,
      scheduleData: [],
      hasMore: true,
    });
  };

  const removeFilter = removedFilter => {
    const { filters } = searchValues;

    const newFilters = removeFilterObject(removedFilter, filters);
    const query = urlParamsToCleanQuery(filterEmptySearch(newFilters));

    if (query === '') {
      replaceUrlState(window.location.pathname);
    } else {
      replaceUrlState(`${window.location.pathname}?${query}`);
    }

    updateSearchValues({
      filters: newFilters,
      page: 1,
      scheduleData: [],
      hasMore: true,
    });
  };

  const addFilters = (filterSelected, newFilters) => {
    updateFilters({ [filterSelected]: newFilters });
  };

  const handleInputChange = value => {
    updateFilters({ search: value });
  };

  const { hasMore, scheduleData, filters, complete } = searchValues;

  return (
    <div className="finder">
      <ScheduleFilter
        removeFilter={removeFilter}
        addFilters={addFilters}
        contactId={contactId}
        filters={cleanFilters(filters)}
        c={c}
        enabled={searchAndFilterEnabled}
      />
      <ScheduleSearchBar
        search={fetchSearch(searchValues)}
        onSearch={debounce(handleInputChange, 500)}
        enabled={searchAndFilterEnabled}
      />
      <ScheduleTable
        searchComplete={complete}
        loadMoreSchedules={getTickets}
        schedules={scheduleData}
        hasMore={hasMore}
      />
    </div>
  );
};

export default Schedule;

Schedule.propTypes = {
  contactId: PropTypes.number.isRequired,
};
