import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const EVENT_MESSAGE_SETTINGS_ENDPOINT = (eventId, userId) =>
  `/api/v1/events/${eventId}/messages/settings/${userId}`;

const EventMessageRibbon = ({
  userId,
  eventId,
  isSubscribed,
  canSeeTickets,
  eTicketed
}) => {
  const [subscribed, setSubscribed] = useState(isSubscribed);

  const onClickToggleSubscribed = async event => {
    event.preventDefault();
    const response = await axios.patch(
      EVENT_MESSAGE_SETTINGS_ENDPOINT(eventId, userId),
      { subscribed: !subscribed },
    );
    setSubscribed(!subscribed);
  };

  return (
    <div className="event-message-ribbon">
      <li className="btn">
        <a href={`/events/${eventId}`}>
          <i className="fa fa-list-ul fa-2x"></i>
          <label>Details</label>
        </a>
      </li>
      {canSeeTickets && (
        <li className="btn">
          <a href={`/events/${eventId}/tickets`}>
            {eTicketed && (<i className="fa fa-ticket-e fa-2x"></i>)}
            {!eTicketed && (<i className="fa fa-ticket-horz fa-2x"></i>)}
            <label>Tickets</label>
          </a>
        </li>
       )}
      <li className="btn">
        <a className="pressed">
          <i className="fa fa-comments fa-2x"></i>
          <label>Messages</label>
        </a>
      </li>
      <li className="btn" onClick={onClickToggleSubscribed}>
        <a href="">
           <i
            className={subscribed ? 'fa fa-star fa-2x' : 'fa fa-star-o fa-2x'}
          ></i>
          <label>{subscribed ? 'Unsubscribe' : 'Subscribe'}</label>
        </a>
      </li>
    </div>
  );
};

EventMessageRibbon.propTypes = {
  userId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
};

export default EventMessageRibbon;
