import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import PropsRoute from './../PropsRoute';
import Manager from './Manager';

const App = ({ conventions, convention, admin }) => (
  <div>
    <BrowserRouter>
      <PropsRoute
        path="/satellite_conventions/:id?"
        component={Manager}
        conventions={conventions}
        convention={convention}
        admin={admin}
      />
    </BrowserRouter>
  </div>
);

App.propTypes = {
  conventions: PropTypes.arrayOf(PropTypes.object).isRequired,
  convention: PropTypes.shape().isRequired,
  admin: PropTypes.bool.isRequired,
};

export default App;
