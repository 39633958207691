import React, { useState, useEffect } from 'react';
import axios from 'axios';
import IdleTimer from 'react-idle-timer';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import { useActionCable } from 'use-action-cable';
import Loading from '../search/EventFinder/Loading';
import Message from './Message';
import {
  filterOutDeletedMessages,
  getUpdatedMessages,
  notEmpty,
} from './helpers/utils';

const EVENT_MESSAGES_ENDPOINT = id => `/api/v1/events/${id}/messages`;
const DELETE_EVENT_MESSAGE_ENDPOINT = (eventId, id) =>
  `/api/v1/events/${eventId}/messages/${id}`;
const EVENT_MESSAGE_SETTINGS_ENDPOINT = (eventId, userId) =>
  `/api/v1/events/${eventId}/messages/settings/${userId}`;

const CHECK_FOR_UPDATES_TIMER = 1000 * 15;
const ACTIVITY_TIMEOUT_TIMER = 1000 * 60 * 5;
let currentTimer;

/* eslint-disable camelcase */
/* eslint-disable no-console */
const Messages = ({
  eventId,
  userId,
  userCanMute,
  userIsEventManager,
  actionCableOn,
}) => {
  const [messages, setMessages] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [active, setActive] = useState(true);
  const [lastTimeout, setLastTimeout] = useState(moment());

  // Check every [n] seconds for more messages
  // Check if new/updated message records
  // Return if user has been inactive on the page for [m] minutes
  const checkForUpdatedMessages = async () => {
    if (!active) {
      return;
    }

    const response = await axios.get(
      `${EVENT_MESSAGES_ENDPOINT(eventId)}?since=${moment()
        .subtract(30, 'seconds')
        .format()}&pagination=false`,
    );
    const { event_messages: recentMessages } = response.data.data;

    if (notEmpty(recentMessages)) {
      setMessages(currentMessages => {
        return filterOutDeletedMessages(
          getUpdatedMessages(currentMessages, recentMessages),
        );
      });
    }

    clearTimeout(currentTimer);
    currentTimer = setTimeout(
      () => checkForUpdatedMessages(),
      CHECK_FOR_UPDATES_TIMER,
    );
  };

  const startTimer = async () => {
    clearTimeout(currentTimer);

    currentTimer = setTimeout(
      () => checkForUpdatedMessages(),
      CHECK_FOR_UPDATES_TIMER,
    );
  };

  const channelHandlers = channelInfo => ({
    connected() {
      console.log('Websocket connected to', channelInfo);
    },
    disconnected() {
      console.log('Websocket disconnected from', channelInfo);
    },
    received: msg => {
      console.log('Websocket received message', { ...channelInfo, ...msg });
      setMessages(currentMessages => {
        return filterOutDeletedMessages(
          getUpdatedMessages(currentMessages, [msg]),
        );
      });
    },
  });

  if (actionCableOn) {
    useActionCable(
      { channel: 'EventMessageChannel', id: eventId },
      channelHandlers({ channel: 'EventMessageChannel', id: eventId }),
    );
  }

  // Loads messages by pagination page
  const loadMoreMessages = async page => {
    await axios
      .get(`${EVENT_MESSAGES_ENDPOINT(eventId)}?page=${page}`)
      .then(response => {
        const { event_messages: eventMessages } = response.data.data;

        setMessages(currentMessages => {
          return filterOutDeletedMessages([
            ...currentMessages,
            ...eventMessages,
          ]);
        });

        // Signal infite scroll if more pages are available
        setHasMore(
          response.data.meta.pagination.current_page <
            response.data.meta.pagination.total_pages,
        );
      });
  };

  const loadFromTimeout = async () => {
    const response = await axios.get(
      `${EVENT_MESSAGES_ENDPOINT(eventId)}?since=${lastTimeout
        .subtract(30, 'seconds')
        .format()}&pagination=false`,
    );
    const { event_messages: recentMessages } = response.data.data;

    if (notEmpty(recentMessages)) {
      setMessages(currentMessages => {
        return filterOutDeletedMessages(
          getUpdatedMessages(currentMessages, recentMessages),
        );
      });
    }
  };

  const onDelete = async message => {
    await axios.delete(DELETE_EVENT_MESSAGE_ENDPOINT(eventId, message.id));
  };

  // Mutes/Unmutes a user
  const toggleMute = async message => {
    const { creator } = message;
    await axios.patch(EVENT_MESSAGE_SETTINGS_ENDPOINT(eventId, creator.id), {
      muted: !creator.muted,
    });
  };

  if (!actionCableOn) {
    useEffect(() => {
      clearTimeout(currentTimer);
      startTimer();
    }, [active]);
  }

  return (
    <div>
      <IdleTimer
        timeout={ACTIVITY_TIMEOUT_TIMER}
        onActive={() => {
          loadFromTimeout();
          setActive(true);
        }}
        onIdle={() => {
          setLastTimeout(moment());
          setActive(false);
        }}
      ></IdleTimer>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreMessages}
        loader={<Loading key={0} />}
        threshold={100}
        hasMore={hasMore}
      >
        {messages
          .sort((a, b) => b.id - a.id)
          .map(message => (
            <Message
              key={message.id}
              message={message}
              onDelete={onDelete}
              toggleMute={toggleMute}
              userId={userId}
              eventId={eventId}
              userCanMute={userCanMute}
              userIsEventManager={userIsEventManager}
            />
          ))}
      </InfiniteScroll>
    </div>
  );
};

export default Messages;
