import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import FinderHeader from './EventFinder/FinderHeader';
import FinderResults from './EventFinder/FinderResults';
// import SearchOptions from './EventFinder/SearchOptions';
import SearchFilter from './EventFinder/SearchFilter';
import SearchQuery from './EventFinder/SearchQuery';
import qs from 'qs';
import axios from 'axios';
import es6ObjectAssign from 'es6-object-assign';
import { isEmpty } from 'ramda';
import debounce from '../../helpers/debounce';

class ModerationFinderContainer extends Component {
  static displayName = 'ModerationFinderContainer';

  constructor(props) {
    super(props);

    this.state = {
      searchFilter: {
        hour: [],
        day: [],
        category: [],
        host: [],
        game: [],
        ids: [],
      },
      searchQuery: { search: '' },
      dayMapping: {},
      stringifiedQuery: '',
    };

    this.handleSearchFiltersChange = this.handleSearchFiltersChange.bind(this);
    this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
    this.handleSearchQueryChange = debounce(this.handleSearchQueryChange, 500);
  }

  handleSearchQueryChange(search) {
    search = search.replace('&', '%26').replace('#', '%23');
    this.setState({ searchQuery: { search } }, this.reloadData);
  }

  handleSearchFiltersChange(searchFilter) {
    this.setState({ searchFilter }, this.reloadData);
  }

  loadMetaDay() {
    let metaDayUrl = '/api/event_search/meta_days';

    axios.get(metaDayUrl).then(res => {
      this.setState({ dayMapping: res.data });
    });
  }

  componentWillMount() {
    this.loadMetaDay();
    let searchQuery;
    let locationObject = qs.parse(window.location.search.substring(1));
    let stringifiedQuery = this.stringifyQuery(locationObject);
    if (locationObject['search']) {
      searchQuery = {
        search: locationObject['search']
          .replace('&', '%26')
          .replace('#', '%23'),
      };
    } else {
      searchQuery = { search: locationObject['search'] };
    }
    let searchFilter = {
      category: this.getArrayFromParam(locationObject, 'category') || [],
      day: this.getArrayFromParam(locationObject, 'day') || [],
      game: this.getArrayFromParam(locationObject, 'game') || [],
      hour: this.getArrayFromParam(locationObject, 'hour') || [],
      host: this.getArrayFromParam(locationObject, 'host') || [],
      ids: this.getArrayFromParam(locationObject, 'ids') || [],
    };

    this.setState({
      searchFilter,
      searchQuery,
      stringifiedQuery,
    });
  }

  reloadData() {
    let searchQuery = this.stringifyQuery(this.state.searchQuery);
    let searchFilter = this.stringifyQuery(this.state.searchFilter);
    let stringifiedQuery = [];

    if (!isEmpty(this.state.searchQuery.search)) {
      stringifiedQuery.push(searchQuery);
    }
    if (!isEmpty(searchFilter)) {
      stringifiedQuery.push(searchFilter);
    }

    this.changeCurrentUrl(searchQuery, this.state.searchFilter);
    this.setState({
      stringifiedQuery: stringifiedQuery.filter(i => !isEmpty(i)).join('&'),
    });
  }

  stringifyQuery(params) {
    if (params.game) {
      params.game = this.getArrayFromParam(params, 'game');
    }
    if (params.host) {
      params.host = this.getArrayFromParam(params, 'host');
    }
    if (params.category) {
      params.category = this.getArrayFromParam(params, 'category');
    }
    if (params.ids) {
      params.ids = this.getArrayFromParam(params, 'ids');
    }
    if (params.search) {
      // this is used to take the non-encoded query params and encode them for passing to the API
      params.search = params.search.replace('&', '%26').replace('#', '%23');
    }

    return qs
      .stringify(params, {
        encode: false,
        indices: false,
        arrayFormat: 'brackets',
      })
      .replace(/ /g, '+');
  }

  changeCurrentUrl(searchQuery, searchFilter) {
    searchFilter = qs.stringify(searchFilter, {
      encode: false,
      indices: false,
    });

    let urlString = [];

    if (this.state.searchQuery.search !== '') {
      urlString.push(searchQuery);
    }
    if (searchFilter) {
      urlString.push(searchFilter);
    }

    if (typeof gtag === 'function') {
      let analytics = {};
      for (let item in urlString) {
        let pairs = urlString[item].split('&');
        for (let pair in pairs) {
          let key = 'ef_' + pairs[pair].split('=')[0];
          let value = pairs[pair].split('=')[1];
          if (key in analytics) {
            analytics[key].push(value);
          } else {
            analytics[key] = [value];
          }
        }
      }
      gtag('event', 'event_moderation_search', analytics);
    }

    const qp = urlString
      .filter(i => !isEmpty(i))
      .join('&')
      .replace(/ /g, '+');
    qp === ''
      ? window.history.replaceState({}, '', window.location.pathname)
      : window.history.replaceState(
          {},
          '',
          window.location.pathname + `?${qp}`,
        );
  }

  getArrayFromParam(param, key) {
    if (typeof param[key] === 'string') {
      return param[key]
        .replace('&', '%26')
        .replace('#', '%23')
        .split();
    } else if (typeof param[key] === 'object') {
      let elements = [];
      param[key].forEach(function(element) {
        elements.push(element.replace('&', '%26').replace('#', '%23'));
      });
      return elements;
    } else {
      return param[key];
    }
  }

  render() {
    const { ids } = this.props;
    return (
      <div>
        <SearchFilter
          dayMapping={this.state.dayMapping}
          onChange={this.handleSearchFiltersChange}
          searchFilter={this.state.searchFilter}
          searchQuery={this.state.stringifiedQuery}
        />
        <SearchQuery
          onChange={this.handleSearchQueryChange}
          defaultQuery={this.state.searchQuery.search}
        />
        <FinderResults
          ids={ids}
          searchQuery={this.state.stringifiedQuery}
          selectedEventId={this.props.selectedEventId}
        />
      </div>
    );
  }
}

export default ModerationFinderContainer;
