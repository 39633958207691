import { Controller } from 'stimulus';
import axios from 'axios';

const CONTACT_SEARCH_ENDPOINT = (id) => `/api/v1/contacts/${id}`;
const SWITCH_CONVENTION_ENDPOINT = `/api/v1/sessions/switch_convention`

export default class extends Controller {
  static targets = ['contactId', 'contactMenu', 'contactName', 'contactLinks', 'currentUser'];

  // Toggles the visibility of a dropdown submenu. The submenu should be the
  // only sibling to the anchor element this method is attached to.
  //
  toggleSubMenu(event) {
    let anchor = event.target;
    // Bubble up to the anchor element
    while (anchor.tagName != "A") anchor = anchor.parentElement;
    let sibling = anchor.nextElementSibling;

    if (!sibling) {return;}

    if (sibling.classList.contains('hide')) {
      let chevron = sibling.parentElement.querySelector('.fa-chevron-down')
      chevron.classList.remove('fa-chevron-down')
      chevron.classList.add('fa-chevron-up')

      sibling.classList.remove('hide');
    } else {
      let chevron = sibling.parentElement.querySelector('.fa-chevron-up')
      chevron.classList.remove('fa-chevron-up')
      chevron.classList.add('fa-chevron-down')

      sibling.classList.add('hide');
    }
  }

  // Switch conventions via api and then reload the page. This passes the request through
  // convention aware pages concern so that convention specific pages take precedence over
  // this function.
  //
  async switchConvention(event) {
    await axios.post(SWITCH_CONVENTION_ENDPOINT, {'convention_id': event.target.value})
    .then(res => {
      location.reload();
    })
    .catch(error => {
    });
  }

  // Hides a submenu when its parent is no longer selected.
  //
  hideSubMenu(event) {
    var subMenu = event.target.querySelector('.sidebar-nav-submenu')
    var chevron = event.target.querySelector('.fa-chevron-right')

    chevron.classList.add('hide')
    subMenu.classList.add('hide')
  }

  // Shows a submenu when its parent is selected.
  //
  showSubMenu(event){
    var subMenu = event.target.querySelector('.sidebar-nav-submenu')
    var chevron = event.target.querySelector('.fa-chevron-right')

    chevron.classList.remove('hide')
    subMenu.classList.remove('hide')
  }

  // Utilizes the CONTACT_SEARCH_ENDPOINT to fetch a contact's name
  // by id. Fills out submenu of links for the contact like /profile,
  // /packets, etc. Pop-up message if contact is not found.
  //
  async contactSearch(event){
    event.preventDefault();
    const id = parseInt(this.contactIdTarget.value);
    const flashContainer = document.querySelector('[data-flash]');
    const errorJson = {
      user: parseInt(this.currentUserTarget.getAttribute('user-id')),
      nId: Math.floor(Math.random() * 1000).toString(),
      text: "",
      icon: 'fa-alert',
      level: 'high',
      timeout: 5000
    }

    this.contactMenuTarget.classList.add('hide');

    if(isNaN(id) || id <= 0 || id > 1000000) {
      errorJson.text = "Invalid Account ID. Make sure ID is between 1 and 1,000,000."
      flashContainer.dataset.flash = JSON.stringify(errorJson);
      return;
    }

    await axios.get(CONTACT_SEARCH_ENDPOINT(id))
    .then(response => {
      var contact = response.data.data.contact;
      this.contactNameTarget.textContent = `(${contact.id}) ${contact.name.toUpperCase()}`;

      Array.from(this.contactLinksTarget.children).forEach(element => {
        let ele = element.querySelector('a');
        let link = decodeURI(ele.getAttribute('template'));
        ele.setAttribute('href', link.replace('_placeholder', id));
      });

      this.contactMenuTarget.classList.remove('hide');
      this.contactLinksTarget.classList.remove('hide');
      let chevron = document.querySelector('#contact-chevron');
      chevron.classList.remove('fa-chevron-down')
      chevron.classList.add('fa-chevron-up')
    })
    .catch(error => {
      errorJson.text = "Contact not found."
      flashContainer.dataset.flash = JSON.stringify(errorJson);
    });
  }
}
