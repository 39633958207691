import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import axios from 'axios';
import { complement, either, isNil, isEmpty, split } from 'ramda';

let currentTimer;
const isValidValue = complement(either(isNil, isEmpty));

// 5 minutes do a HEAD request for server time
const SYNC_INTERVAL = 5 * 60 * 1000;

// If off by by 2 minutes we sync with server time
const DELTA_INTERVAL = 2 * 30 * 1000;

const formatDoubleDigits = number =>
  split(
    '',
    number.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }),
  );

const renderer = ({ days, hours, minutes, seconds, completed, props }) => {
  if (completed) {
    const {
      onCompletionTrigger,
      onCompletionParams,
      showWishlistButton,
      hideWhenComplete,
      timeSlotId,
      housingBlockId,
    } = props;

    // Call the function on completion
    if (isValidValue(onCompletionTrigger) && isValidValue(onCompletionParams)) {
      window[onCompletionTrigger](
        onCompletionParams,
        timeSlotId,
        housingBlockId,
      );
    }

    if (showWishlistButton) {
      const wishlistButton = document.querySelector('#wishlist-submit');

      if (wishlistButton) {
        wishlistButton.classList.remove('disabled');
        wishlistButton.disabled = false;
      }
    }

    if (hideWhenComplete) {
      return null;
    }
  }

  const [dayDigit1, dayDigit2] = formatDoubleDigits(days);
  const [hourDigit1, hourDigit2] = formatDoubleDigits(hours);
  const [minuteDigit1, minuteDigit2] = formatDoubleDigits(minutes);
  const [secondDigit1, secondDigit2] = formatDoubleDigits(seconds);

  return (
    <center>
      <div className="countdown-timer">
        <div className="clock-bezel-main">
          <div className="unit">
            <div className={`digit number-${dayDigit1}`} />
            <div className={`digit number-${dayDigit2}`} />
            <div className="label">Days</div>
          </div>
          <div className="separator">:</div>
          <div className="unit">
            <div className={`digit number-${hourDigit1}`} />
            <div className={`digit number-${hourDigit2}`} />
            <div className="label">Hours</div>
          </div>
          <div className="separator">:</div>
          <div className="unit">
            <div className={`digit number-${minuteDigit1}`} />
            <div className={`digit number-${minuteDigit2}`} />
            <div className="label">Minutes</div>
          </div>
          <div className="separator">:</div>
          <div className="unit">
            <div className={`digit number-${secondDigit1}`} />
            <div className={`digit number-${secondDigit2}`} />
            <div className="label">Seconds</div>
          </div>
        </div>
      </div>
      <div className="start-date" />
    </center>
  );
};

/* eslint-disable camelcase */
const CountdownTimer = ({
  timeSlotId,
  housingBlockId,
  accessAt,
  onCompletionTrigger,
  showWishlistButton,
  onCompletionParams,
  hideWhenComplete,
}) => {
  const [serverTimeDifference, setServerTimeDifference] = useState(null);

  const AbsoluteDifference = (a, b) => Math.abs(a - b);
  const checkServerTime = async () => {
    const { headers } = await axios.head('/time_sync.txt');
    const serverDate = new Date(headers.date).getTime();
    const currentDate = Date.now();

    if (AbsoluteDifference(serverDate, currentDate) > DELTA_INTERVAL) {
      setServerTimeDifference(serverDate - currentDate);
    }

    clearTimeout(currentTimer);
    currentTimer = setTimeout(() => checkServerTime(), SYNC_INTERVAL);
  };

  const startSyncTimer = async () => {
    clearTimeout(currentTimer);

    currentTimer = setTimeout(() => checkServerTime(), SYNC_INTERVAL);
  };

  useEffect(() => {
    clearTimeout(currentTimer);
    startSyncTimer();
  }, []);

  const millisecondsRemaining = new Date(accessAt * 1000);
  let currentDate;

  if (serverTimeDifference) {
    console.log(`Syncing with serverTime off by ${serverTimeDifference}`);
    currentDate = () => Date.now() + serverTimeDifference;
  } else {
    currentDate = () => Date.now();
  }

  return (
    <div className="countdown-time-slot" data-slot={timeSlotId}>
      <Countdown
        date={millisecondsRemaining}
        renderer={renderer}
        onCompletionTrigger={onCompletionTrigger}
        onCompletionParams={onCompletionParams}
        showWishlistButton={showWishlistButton}
        timeSlotId={timeSlotId}
        housingBlockId={housingBlockId}
        now={currentDate}
        hideWhenComplete={hideWhenComplete}
      />
    </div>
  );
};

export default CountdownTimer;
