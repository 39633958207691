import React from 'react';

const Tooltip = ({ position, direction, content, onClick, buttonContent, altButtonText, altOnClick, arrowOffset = '75%' }) => {
  // values are possibly undefined, just need two of them (x,y)
  const tooltipPosition = {
    top: position.top,
    left: position.left,
    right: position.right,
    bottom: position.bottom,
  };

  const arrowStyle = {
    [direction === 'left' || direction === 'right' ? 'top' : 'left ']: arrowOffset,
  };

  return (
    <div className='training-tooltip' style={{ ...tooltipPosition }}>
      <div className={`arrow-${direction}`} style={arrowStyle}></div>
      <p className='tooltip-content-p'>{content}</p>
      <div className='next-button-row' style={{display: 'flex', justifyContent: altOnClick ? 'space-between' : 'end'}}>
        {(altButtonText && <div className='banner-button back-button' onClick={altOnClick}>{altButtonText}</div>)}
        {(buttonContent && <div className='banner-button next-button' onClick={onClick}>{buttonContent}</div>)}
      </div>
    </div>
  );
};

export default Tooltip;
