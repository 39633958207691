document.addEventListener('DOMContentLoaded', () => {
  const taskForm = document.getElementById("task_form");
  if (!taskForm) return;

  const assignee     = document.getElementById('task_assigned_to_id');
  const assigneeId   = assignee.value;
  const submitButton = document.getElementById('create_task_button');

  submitButton.addEventListener('click', (event) => {
    const newAssigneeId = assignee.value;
    const message       = "Assigning this task to a group will make all members of the group subscribers and send email notifications to all. Are you sure you want to do this?"

    if ((newAssigneeId !== '' || newAssigneeId !== undefined) && newAssigneeId !== assigneeId) {
      const option = document.getElementById(`${newAssigneeId}`);

      if (option.value === 'true' && !confirm(message)) {
        event.preventDefault();
        return;
      };
    };

    taskForm.submit();
  });
});
