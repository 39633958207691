import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TicketStatsIcon from './TicketStatsIcon';

const TicketStats = ({ tickets, handleButtonClick, currentFilter }) => {
  const redeemedCount = (contacts) => {
    return contacts.reduce((accumulator, currentContact) => {
      return accumulator + currentContact.tickets.filter(x => x.redeemed).length;
    }, 0);
  };

  const guestsCount = (contacts) => {
    return contacts.filter(x => x.status === 'guests').reduce((accumulator, currentContact) => {
      return accumulator + currentContact.tickets.filter(x => !x.redeemed).length;
    }, 0);
  };

  const Button = ({ onClick, count, label, currentFilter }) => {
    const buttonClass = classNames({
      'btn disabled-filter': (label.toLowerCase() !== currentFilter),
      'btn active-filter': (label.toLowerCase() === currentFilter),
    });

    return (
      <li className={buttonClass} onClick={onClick}>
        <a href="#">
          <TicketStatsIcon
            status={label.toLowerCase()}
            count={count}
            title={`Electronic Ticket ${label} Count`}
          />
          <label>{label}</label>
        </a>
      </li>
    );
  };

  return (
    <div className="ribbon-row">
      <ul className="left">
        <Button
          onClick={() => handleButtonClick("redeemed")}
          count={redeemedCount(tickets)}
          label="Redeemed"
          currentFilter={currentFilter}
        />
        <Button
          onClick={() => handleButtonClick("guests")}
          count={guestsCount(tickets)}
          label="Guests"
          currentFilter={currentFilter}
        />
      </ul>
    </div>
  );
};

TicketStats.propTypes = {
  tickets: PropTypes.array.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  currentFilter: PropTypes.string,
};

export default TicketStats;
