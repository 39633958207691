import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Scanner from '../scanner/Scanner';
import ScannerBar from './ScannerBar';
import ManualScanModal from './ManualScanModal';

const BADGE_INFO_ENDPOINT = (conId, badgeCode) => `/api/v1/cons/${conId}/badges/${badgeCode}`;

const ErrorModal = ({ message, close }) => {
  return (
    <div className='scanning-modal' style={{backgroundColor: 'white'}}>
      <div className='scanning-modal-header'>
        <h4 style={{margin: '0px 0px 0px 0px'}}>Error</h4>
      </div>
      <div className='scanning-modal-content' style={{backgroundColor: 'white', color: 'black'}}>
        <p>{message}</p>
      </div>
      <div style={{display:'flex'}}>
      <div className='button' onClick={close} style={{margin: '0px 0px 10px 0px'}}>OK</div>
      </div>
    </div>
  );
};

const HostScanning = ({conId}) => {
  const [focus, setFocus] = useState(document.hasFocus());
  const [showManualScan, setShowManualScan] = useState(false);
  const [showError, setShowError] = useState('');

  const onScan = async (badgeCode) => {
    await axios.get(BADGE_INFO_ENDPOINT(conId, badgeCode))
      .then(response => {
        let isHost = response.data.is_event_host;
        let hostId = response.data.id;
        let hostName = response.data.first_name + ' ' + response.data.last_name;
        if (isHost) {
          window.location.href = `/schedules/${hostId}/host_scanning`;
        } else {
          setShowError(`${hostName} is not hosting any E-Ticketed events.`)
        }
      })
      .catch(error => {
        setShowError('Host badge not found');
        console.log(error);
      });
  };

  return (
    <div>
      <div className='page-title'>Host Scanning</div>
      <div className='redemptions-container'>
        {
          showManualScan && <div className='content' style={{padding: 0}}>
            <ManualScanModal scan={onScan} close={() => setShowManualScan(false)}/>
          </div>
        }
        {
          showError && <div className='content' style={{padding: 0}}>
            <ErrorModal message={showError} close={() => setShowError(false)}/>
          </div>
        }
        <ScannerBar focus={focus} guestScanning={false} setShowManualScan={setShowManualScan} message={"Scan an Event Host's Badge"}/>
        <Scanner onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} onScan={onScan} pattern={'^[B,b]-[A-Za-z0-9]{12}$'} timeout={250}/>
        <div className='centered'>
        <p>Scan the badge of the Event Host that you want to scan badges on behalf of.</p>
        </div>
      </div>
    </div>
  );
}

export default HostScanning;