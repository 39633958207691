/*
 * Javascript for Pop-Up Gen Con Homepage @ /pop-up
 */
document.addEventListener('DOMContentLoaded', () => {
  
  if (!document.getElementById('pugc-homepage')) return;
  const elements = document.querySelectorAll(
    '#pugc-homepage a[data-popup-description-link]',
  );

  if (elements.length > 0) {
    elements.forEach(element => {
      /*
      * Toggles the Pop-Up Event Description table row in table on the Pop-Up Gen
      * Con Homepage.
      *
      */
      element.addEventListener('click', event => {
        event.preventDefault();

        const popupEventId = element.dataset.popupDescriptionLink;
        console.log(popupEventId);

        const row = document.querySelector(
          `#pugc-homepage tr[data-popup-row2='${popupEventId}']`,
        );

        const display = row.style.display;

        if (display === 'none') {
          row.style.display = 'table-cell';
        } else {
          row.style.display = 'none';
        }
      });
    });
  }
});
