import { Controller } from 'stimulus';
import animateScrollTo from 'animated-scroll-to';
import { DateTime } from 'luxon';

export default class extends Controller {
  static targets = [
    'postDate',
    'replyTo',
    'originalPost',
    'newPostForm',
    'postCopy',
    'postLink',
    'newPostForm',
  ];

  initialize() {
    if (CKEDITOR.instances['ckeditor-bbcode']) {
      CKEDITOR.instances['ckeditor-bbcode'].setData('');
    }
  }

  connect() {
    if (window.location.hash != '' && window.location.hash.match('replyto-')) {
      const replyID = window.location.hash.substring(1).split('-')[1];

      setTimeout(function() {
        document.querySelector(`[data-id="${replyID}"]`).click();
      }, 2000);
    }
  }

  moreSubjects(event) {
    event.preventDefault();

    const chevron = event.target;

    if (chevron.classList.contains('fa-chevron-circle-up')) {
      chevron.classList.remove('fa-chevron-circle-up');
      chevron.classList.add('fa-chevron-circle-down');
    } else if (chevron.classList.contains('fa-chevron-circle-down')) {
      chevron.classList.remove('fa-chevron-circle-down');
      chevron.classList.add('fa-chevron-circle-up');
    }

    const breadcrumb = chevron.closest('[data-breadcrumb]');

    if (breadcrumb) {
      const moresubjects = breadcrumb.querySelector('.moresubjects');

      moresubjects.classList.toggle('hide');
    }
  }

  animateToNewForm() {
    // Animate to the new reply form
    animateScrollTo(this.newPostFormTarget);
  }

  async filterCkeditor() {
    // This filter is *nearly* identical to the filter in app/assets/javascripts/ckeditor/config.js used for
    // formatting html for copy-paste actions.   Its very likely changes here need will also need to be made
    // the forums.js file.
    //
    // Warning: - The ordering of this list relative to other elements matters.  Make sure
    // you undertand the effects of adding, deleting, or modifying elements in the list!
    // Reference CKEDITOR filter APIs for more info: http://docs.ckeditor.com/
    //
    const filter = await new CKEDITOR.filter(
      'div[!data-postquote]; blockquote b br i ul ol li strong u; img[!src, alt]; a[!href]',
    );
    await CKEDITOR.instances['ckeditor-bbcode'].setActiveFilter(filter);
  }

  async insertMarkup(html) {
    await CKEDITOR.instances['ckeditor-bbcode'].getData();
    await CKEDITOR.instances['ckeditor-bbcode'].insertHtml(html);
  }

  // This click handler is to captures the click on the cancel button which appears
  // only when your in the process of replying to a post. It will clear out the CKEDITOR as
  // well as removes the button
  cancel(event) {
    let dataPost = document.querySelector('[data-post]');
    let postCopy = this.postCopyTarget;

    event.preventDefault();
    CKEDITOR.instances['ckeditor-bbcode'].setData('');

    if (dataPost !== null) {
      dataPost.value = '';
    };

    if (postCopy.dataset.hidden) {
      document.querySelector('#new-post').classList.add('hide');
      postCopy.classList.add('hide');
      document.querySelector('[data-postlink]').classList.remove('hide');
      document.querySelector('[data-postoriginal]').classList.remove('hide');
    }
  }

  //Grabs the html in the content of the post and inserts the
  // html into the CKEDITOR with the a link back to the original post.
  //
  async reply(event) {
    const { currentTarget } = event;
    const postId = currentTarget.dataset.id;

    this.animateToNewForm();

    const postContainer = document.querySelector(
      `#post-${postId} .post-container`,
    );

    const authorName = document.querySelector(
      `#post-${postId} [data-postauthor]`,
    ).dataset.postauthor;

    let postCopy = this.postCopyTarget;
    let copy = postCopy.dataset.postcopy;

    if (postCopy.dataset.hidden) {
      document.querySelector('#new-post').classList.remove('hide');
      postCopy.classList.remove('hide');
      document.querySelector('[data-postlink]').classList.add('hide');
    }

    const returnToPost = document
      .querySelector(`#post-${postId} [data-postlink]`)
      .getAttribute('href');

    await this.filterCkeditor();

    // Styling depends div[data-postquote] a:first-child - keep in mind if changing this output.
    const url = `<div data-postquote='true'>
            <a href='${returnToPost}'>
            <i>&#xf10d;</i>
            ${authorName} wrote:</a>
          `;

    const html = `${url}<br>${postContainer.innerHTML}</div>\n`;
    this.insertMarkup(html);

    copy += ` in reply to <a href='#post-${postId}'>${authorName}</a>`;

    document.querySelector('[data-postoriginal]').classList.remove('hide');
    document.querySelector('[data-post]').value = postId;
    document.querySelector('[data-postcopy]').innerHTML = copy;
  }
}
