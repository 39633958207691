import axios from 'axios';
import {
  SCHEDULE_ENDPOINT,
  SCHEDULE_META_ENDPOINT,
  SCHEDULE_INDEX_STATUS_ENDPOINT,
} from '../../../api';
import { urlParamsToCleanQuery } from './searchHelpers';

export const fetchSchedules = ({ contactId, page, filters = {} }) => {
  const queryString = urlParamsToCleanQuery({
    ...filters,
    contact_id: contactId,
    page,
  });

  return axios.get(`${SCHEDULE_ENDPOINT}?${queryString}`);
};

export const fetchMetadata = ({ contactId, filters = {}, filter, c }) => {
  const queryString = urlParamsToCleanQuery({
    ...filters,
    contact_id: contactId,
    filter,
    c,
  });

  return axios.get(`${SCHEDULE_META_ENDPOINT}?${queryString}`);
};

export const scheduleIndexStatusSchedule = ({ contactId }) => {
  return axios.get(`${SCHEDULE_INDEX_STATUS_ENDPOINT}?contact_id=${contactId}`);
};
