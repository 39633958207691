import React from 'react';

const ScannerBar = ({ focus, guestScanning, setShowManualScan, message='Ready to scan a badge'}) => {
  let timeoutId;

  const handleMouseDown = () => {
    // timeoutId = setTimeout(() => {
    //   setShowManualScan(true);
    // }, 5000);
  };

  const handleMouseUp = () => {
    // clearTimeout(timeoutId);
  };

  return (
    <div>
      {focus ? (
        guestScanning.enabled ? (
          <div
            className="attendance-scan-bar attendance-scan-prompt"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            <span>
              Scanning Guest Badges of {guestScanning.host.ticketholder.first_name}{' '}
              {guestScanning.host.ticketholder.last_name} <br />
              {guestScanning.host.additional_tickets} Guest Tickets Remaining
            </span>
          </div>
        ) : (
          <div
            className="attendance-scan-bar attendance-scan-prompt"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            <span>{message} &nbsp;</span>
            <i className="fa fa-scanner fa-2x" title="Scan a badge"></i>
          </div>
        )
      ) : (
        <div className="attendance-scan-bar-unfocus">
          <span>Select to enable scanning &nbsp;</span>
          <i className="fa fa-hand-o-up fa-2x" title="Select to enable"></i>
        </div>
      )}
    </div>
  );
};

export default ScannerBar;