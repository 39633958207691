import { Controller } from 'stimulus';
import tippy from 'tippy.js';
import axios from 'axios';
import { DateTime } from 'luxon';

const LAST_MODIFIED_ENDPOINT =
  '/all_events_exports/event_export_last_modified_date';

export default class extends Controller {
  static targets = ['tooltipAjax'];

  fireToolTipAjax() {
    tippy(this.tooltipAjaxTarget, {
      content: 'Loading...',
      theme: 'light',
      onShow(instance) {
        axios.get(LAST_MODIFIED_ENDPOINT).then(res => {
          const { date } = res.data;

          if (date) {
            const currentDayTime = DateTime.fromISO(date, { zone: 'utc' });
            const formatDate = currentDayTime.toRelative();

            const dateMessage = `Generated ${formatDate}`;
            instance.setContent(dateMessage);
          } else {
            instance.setContent('File missing');
          }
        });
      },
    });
  }

  connect() {
    if (this.hasTooltipAjaxTarget) {
      this.fireToolTipAjax();
    }
  }
}
