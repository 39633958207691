import 'genconllc';

import WebpackerReact from 'webpacker-react';
import AttendanceTracking from '../components/attendanceTracking/AttendanceTracking';
import ExhibitorContainer from '../components/exhibitors/ExhibitorContainer';
import FinderContainer from '../components/search/FinderContainer';
import EventRegistrationsContainer from '../components/eventSubmissions/EventRegistrationsContainer';
import Schedule from '../components/schedule/Schedule';
import AvatarUpload from '../components/profile/AvatarUpload';
import Tickets from '../components/tickets/Tickets';
import PaymentMethods from '../components/payment_methods/PaymentMethods';
import SatelliteConventions from '../components/satellite_conventions/App';
import LookingGlass from '../components/LookingGlass/LookingGlass';
import UspsLabels from '../components/fulfillment/UspsLabels';
import Messages from '../components/eventMessages/MessagesWrapper';
import EventMessageRibbon from '../components/eventMessages/EventMessageRibbon';
import ModerationFinderContainer from '../components/eventModerationFilter/ModerationFinderContainer';
import Conversation from '../components/conversations/ConversationWrapper';
import Conversations from '../components/conversations/ConversationsWrapper';
import NotificationsContainer from '../components/notifications/NotificationsWrapper';
import CountdownTimer from '../components/timers/CountdownTimer';
import Scanner from '../components/scanner/Scanner';
import TicketRedemptions from '../components/ticketRedemptions/TicketRedemptionsWrapper';
import DemoTicketRedemptions from '../components/ticketRedemptions/DemoTicketRedemptions';
import ETicketingTraining from '../components/training/ETicketingTraining';
import HostScanning from '../components/ticketRedemptions/HostScanning';
import BarChart from '../components/d3/BarChart';

import setupCSRFToken from '../components/setupCSRFToken';

WebpackerReact.setup({
  AttendanceTracking,
  AvatarUpload,
  Conversation,
  Conversations,
  CountdownTimer,
  EventMessageRibbon,
  EventRegistrationsContainer,
  ExhibitorContainer,
  FinderContainer,
  UspsLabels,
  LookingGlass,
  Messages,
  ModerationFinderContainer,
  NotificationsContainer,
  PaymentMethods,
  SatelliteConventions,
  Scanner,
  Schedule,
  Tickets,
  TicketRedemptions,
  DemoTicketRedemptions,
  ETicketingTraining,
  HostScanning,
  BarChart,
});

// setups CSRF Token
window.addEventListener('DOMContentLoaded', setupCSRFToken);

import 'controllers';
