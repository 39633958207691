import { Controller } from 'stimulus';
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ['timeSelector', 'dateTimeInput', 'latest', 'form', 'statsTable', 'dateTimeField'];

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "EventRegStatsChannel"},
      {
        received: (data) => {
          if (data.event_reg_stat && this.latestTarget.value == 'true') {
            this.updateStatsOnPage(data.event_reg_stat);
          }
        }
      }
    );

    this.adjustDateTimeInputVisibility();
  }

  timeSelectorChanged() {
    const isLatestSelected = this.timeSelectorTarget.value === "latest";

    // Toggle visibility based on selection
    this.dateTimeInputTarget.style.display = isLatestSelected ? "none" : "block";
    this.latestTarget.value = isLatestSelected ? 'true' : 'false';

    if (isLatestSelected) {
      if (this.hasFormTarget) {
        this.formTarget.submit();
      }
    } else {
    //set the current time + 1 minute if 'Selected Time' is chosen
    this.dateTimeFieldTarget._flatpickr.setDate(new Date(new Date().getTime() + 60000), true);
    this.updateStatsOnPage(null);

    }
  }

  adjustDateTimeInputVisibility() {
    this.dateTimeInputTarget.style.display = this.latestTarget.value === 'true' ? "none" : "block";
  }


  updateStatsOnPage(stats) {
    const statsTableBody = this.statsTableTarget.querySelector('tbody');
    statsTableBody.innerHTML = ''; // Clear existing stats before updating

    if (!stats) {
      return; // If no stats, just return.
    }

    Object.entries(stats).forEach(([key, value]) => {
      const tr = document.createElement('tr');
      const tdKey = document.createElement('td');
      const tdValue = document.createElement('td');

      tdKey.textContent = key;

      // Format date-time strings for known date-time fields
      if (key === 'created_at' || key === 'updated_at' || key === 'ticket_requests_updated_since') {
        tdValue.textContent = this.formatDateString(value);
      } else {
        tdValue.textContent = value; // No formatting needed
      }

      tr.appendChild(tdKey);
      tr.appendChild(tdValue);
      statsTableBody.appendChild(tr);
    });
  }

  // Helper function to format date strings as provided
  formatDateString(dateStr) {
    const date = new Date(dateStr);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/New_York'
    };
    let formattedDate = date.toLocaleString('en-US', options);
    formattedDate = formattedDate.replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+ [AP]M)/, '$3/$1/$2 $4');

    return formattedDate;
}

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }
}
