import React, { useState, Fragment, useEffect, useRef } from 'react';
import { complement, either, isEmpty, isNil } from 'ramda';
import axios from 'axios';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import qs from 'qs';

import { DELAYED_JOBS_ENDPOINT, USPS_SHIPMENTS_ENDPOINT } from '../../api';

const CHECK_FOR_UPDATES_TIMER = 1000 * 3; // 7 seconds
const isValidValue = complement(either(isNil, isEmpty));
const SHIPPING_METHOD = 'USPS Priority Mail';
let currentTimer;

const UspsLabels = ({ fulfillmentIds, shippingMethod, batchSize }) => {
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(30);
  const [batchComplete, setBatchComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [complete, setComplete] = useState(false);
  const [delayedJobId, setDelayedJobId] = useState(null);

  useEffect(() => {
    if (delayedJobId) {
      startTimer(delayedJobId);
    }
  }, [delayedJobId]);

  const checkJobStatus = async delayedJobId => {
    const res = await axios.get(`${DELAYED_JOBS_ENDPOINT}/${delayedJobId}`);
    const { status } = res.data;

    clearTimeout(currentTimer);

    if (status === 'complete') {
      setProcessing(false);
      setComplete(true);
    } else {
      currentTimer = setTimeout(
        () => checkJobStatus(delayedJobId),
        CHECK_FOR_UPDATES_TIMER,
      );
    }
  };

  const startTimer = async delayedJobId => {
    currentTimer = setTimeout(
      () => checkJobStatus(delayedJobId),
      CHECK_FOR_UPDATES_TIMER,
    );
  };

  const createLabels = async () => {
    setProcessing(true);
    clearTimeout(currentTimer);

    const uspsPostData = {
      label_batch_id_start: start,
      label_batch_id_end: end,
      fulfillment_ids: fulfillmentIds,
    };

    const { data } = await axios.post(USPS_SHIPMENTS_ENDPOINT, uspsPostData);

    setDelayedJobId(data.job_id);
  };

  const updateStart = event => {
    const value = event.target.value;
    const intValue = parseInt(value.replace(/[^\d]/, ''));

    if ((intValue >= 1 && intValue <= 30) || value === '') {
      const startValue = value === '' ? value : intValue;
      setStart(startValue);
      setComplete(false);
    }
  };

  const updateEnd = event => {
    const value = event.target.value;
    const intValue = parseInt(value.replace(/[^\d]/, ''));

    if ((intValue >= 1 && intValue <= 30) || value === '') {
      const endValue = value === '' ? value : intValue;
      setEnd(endValue);
      setComplete(false);
    }
  };

  const disabledButton = classNames('button', {
    disabled: complete,
  });

  const enablePrintButton = complete && isValidValue(delayedJobId);
  const printUrlOptions = {
    shipping_method: encodeURIComponent(shippingMethod),
    fulfillment_ids: fulfillmentIds.join('+'),
    label_batch_id_start: start,
    label_batch_id_end: end,
  };

  const options = qs.stringify(printUrlOptions, {
    encode: false,
    indices: false,
  });

  const printUrl = `/fulfillments/usps_labels?${options}`;

  const printLabels = event => {
    event.preventDefault();
    window.open(printUrl, '_blank');
  };

  return (
    <div>
      <h3>USPS Priority Mail Labels:</h3>

      <form
        target="blank"
        action="/fulfillments/usps_labels"
        acceptCharset="UTF-8"
        method="get"
      >
        <p>
          <span className="bold">Print labels from batch id: &nbsp;</span>
          <input
            type="text"
            pattern="[0-9]"
            onChange={updateStart}
            name="label_batch_id_start"
            id="label_batch_id_start"
            value={start}
            size={5}
            className="inline-block small-text-input"
          />
          &nbsp;to&nbsp;
          <input
            onChange={updateEnd}
            pattern="[0-9]"
            type="text"
            name="label_batch_id_end"
            id="label_batch_id_end"
            value={end}
            size={5}
            className="inline-block small-text-input"
          />
        </p>
        {!processing && (
          <button
            disabled={complete}
            onClick={createLabels}
            className={disabledButton}
          >
            Create Labels
          </button>
        )}
        {processing && (
          <button className="button disabled" disabled>
            <span className="mini css-spinner"></span>
            Creating Labels
          </button>
        )}
        <button
          onClick={printLabels}
          className={classNames('button', { disabled: !enablePrintButton })}
          disabled={!enablePrintButton}
        >
          Print Labels
        </button>
      </form>
    </div>
  );
};

export default UspsLabels;

UspsLabels.propTypes = {
  batchSize: PropTypes.number.isRequired,
  shippingMethod: PropTypes.string.isRequired,
  fulfillmentIds: PropTypes.array.isRequired,
};
