import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

class PaymentMethodPage extends Component {
  constructor(props) {
    super(props);

    this.state = { paymentMethod: null };

    this.handleShow = this.handleShow.bind(this);
  }

  handleShow(contactId, paymentMethodId) {
    axios
      .get(`/api/v1/contacts/${contactId}/payment_methods/${paymentMethodId}`)
      .then(response =>
        this.setState({ paymentMethod: response.data.data.payment_method }),
      )
      .catch(error => {
        // console.log(error);
      });
  }

  componentDidMount() {
    this.handleShow(
      this.props.match.params.contact_id,
      this.props.match.params.id,
    );
  }

  render() {
    const paymentMethodsPath = `/contacts/${this.props.match.params.contactId}/payment_methods`;

    const renderDetails = () => {
      if (this.state.paymentMethod !== null) {
        return (
          <div className="panel">
            <div className="ribbon-top">
              <ul className="left">
                <li className="btn">
                  <Link
                    to={`/contacts/${this.props.match.params.contact_id}/payment_methods`}
                  >
                    <i className="fa fa-arrow-left fa-2x"></i>
                    <label>Payment Methods</label>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="panel-title">
              {this.state.paymentMethod.card_type} x
              {this.state.paymentMethod.card_digits}
            </div>

            <div className="attributes">
              <div className="attr">
                <div className="name">Cardholder Name:</div>
                <div className="value">
                  {this.state.paymentMethod.cardholder_name}
                </div>
              </div>
              <div className="attr">
                <div className="name">Card Type:</div>
                <div className="value">
                  {this.state.paymentMethod.card_type}
                </div>
              </div>
              <div className="attr">
                <div className="name">Last Digits:</div>
                <div className="value">
                  {this.state.paymentMethod.card_digits}
                </div>
              </div>
              <div className="attr">
                <div className="name">Expiration Date:</div>
                <div className="value">
                  {moment(this.state.paymentMethod.card_expiry).format(
                    'MM/YYYY',
                  )}
                </div>
              </div>
              <div className="attr">
                <div className="name">State:</div>
                <div className="value">{this.state.paymentMethod.state}</div>
              </div>
              <div className="attr">
                <div className="name">Default Method:</div>
                <div className="value">
                  {this.state.paymentMethod.default_method.toString()}
                </div>
              </div>
              <div className="attr">
                <div className="name">Gateway ID:</div>
                <div className="value">
                  {this.state.paymentMethod.gateway_account_id}
                </div>
              </div>
              <div className="attr">
                <div className="name">Gateway Customer ID:</div>
                <div className="value">
                  {this.state.paymentMethod.gateway_customer_id}
                </div>
              </div>
            </div>
          </div>
        );
      }

      return <div>Loading...</div>;
    };

    return <div>{renderDetails()}</div>;
  }
}

export default PaymentMethodPage;
