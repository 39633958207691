/* global document  */
/* global window  */
import axios from 'axios';
import gifFrames from 'gif-frames';
import MicroModal from 'micromodal';
import moment from 'moment-timezone';
import Handlebars from 'handlebars';

document.addEventListener('DOMContentLoaded', () => {
  window.MicroModal = MicroModal;
  window.gifFrames = gifFrames;
  window.moment = moment;
  window.Handlebars = Handlebars;

  MicroModal.init({
    onShow: modal => {
      const ajaxUrl = modal.getAttribute('ajax-url');
      if (ajaxUrl !== null) {
        const modalId = modal.getAttribute('id');
        const bodyId = `${modalId}-body`;
        axios.get(ajaxUrl).then(response => {
          document.getElementById(bodyId).innerHTML = response.data;
        });
      }
    },
    openClass: 'is-open',
    disableScroll: true,
    disableFocus: false,
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    debugMode: false,
  });
});
