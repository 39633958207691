import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, includes, without } from 'ramda';

import {
  popupHeaderStyle,
  formatForDisplay,
  displayName,
  allValuesFromMetadata,
} from '../helpers/displayHelpers';

const ScheduleMetadataSelector = ({
  metaData,
  ogMetaData,
  filterSelected,
  filters,
  closeModal,
  addFilters,
}) => {
  const initialGroupFilter = () => {
    return filters[filterSelected] ? [filters[filterSelected]].flat() : [];
  };

  const [groupFilters, setGroupFilters] = useState(initialGroupFilter);

  const isSelectAllChecked = () => {
    return isEmpty(groupFilters);
  };

  const isFilterChecked = filter => {
    return includes(filter.toString(), groupFilters);
  };

  const addSelectedFilters = () => {
    closeModal();

    if (groupFilters.length === allValuesFromMetadata(filterSelected, ogMetaData).length){
      addFilters(filterSelected, []);
    } else {
      addFilters(filterSelected, groupFilters);
    }
  };

  const toggleSelectedFilter = filter => {
    if (includes(filter.toString(), groupFilters)) {
      setGroupFilters(without([filter.toString()], groupFilters));
    } else {
      setGroupFilters([...groupFilters, filter.toString()]);
    }
  };

  const selectAll = () => {
    setGroupFilters(allValuesFromMetadata(filterSelected, metaData));
  };

  const allCheckedClassNames = classNames('modal-filter-checkbox select-all', {
    checked: isSelectAllChecked(),
  });

  const { doc_count: totalCount } = metaData;

  /* eslint-disable */
  return (
    <div>
      <div className="event-finder-modal-title">
        <h2 style={popupHeaderStyle}>Schedule Filters</h2>
      </div>
      <div className="modal-filters">
        <div onClick={selectAll} role="button" className={allCheckedClassNames}>
          Select All
          <div className="cat-quantity">{totalCount}</div>
        </div>
        {formatForDisplay(filterSelected, metaData).map(data => {
          const { key, doc_count: count } = data;
          const checkedClass = classNames('modal-filter-checkbox', {
            checked: isFilterChecked(key),
          });

          return (
            <div
              onClick={() => toggleSelectedFilter(key)}
              role="button"
              tabIndex="0"
              key={`${key}-${count}`}
              className={checkedClass}
            >
              {displayName(filterSelected, key)}
              <div className="cat-quantity">{count}</div>
            </div>
          );
        })}
      </div>
      <div className="event-filter-close">
        <button type="button" onClick={addSelectedFilters} className="button">
          OK
        </button>
      </div>
    </div>
  );
};

export default ScheduleMetadataSelector;

ScheduleMetadataSelector.propTypes = {
  closeModal: PropTypes.func.isRequired,
  addFilters: PropTypes.func.isRequired,
  filterSelected: PropTypes.string.isRequired,
  filters: PropTypes.shape().isRequired,
  metaData: PropTypes.shape().isRequired,
};
