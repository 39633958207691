import { Controller } from 'stimulus';
import { DateTime } from 'luxon';

import { validateForms } from '../helpers/clientSideValidations';

export default class extends Controller {
  initialize() {
    this.setPastOrFutureEvent();
    this.setTimeToggle();
    this.setSidebarDateCountdown();
    this.setCollapseItems();
    this.setClientSideValidations();
  }

  getDate(element) {
    const postDate = element.dataset.postDate;

    return DateTime.fromISO(postDate);
  }

  getEventDate(element) {
    const eventDate = element.dataset.eventDate;

    return DateTime.fromISO(eventDate);
  }

  getTime(date) {
    return date.toFormat("'on' D 'at' t");
  }

  setPastOrFutureEvent() {
    const allEventDates = document.querySelectorAll('[data-event-date]');
    const currentDate   = DateTime.local();

    allEventDates.forEach(element => {
      const eventDate  = this.getEventDate(element);
      const eventClass = eventDate > currentDate ? 'future-event' : 'past-event';

      element.classList.add(eventClass);
    });
  }

  setDateAndTime(element, date, time) {
    const currentDate = DateTime.local();
    let fromNow = date.toRelative();

    if (date > currentDate) {
      fromNow = 'now';
    }

    const dataWords = element.querySelector('[data-words]');
    const dataTime = element.querySelector('[data-time]');

    dataWords.textContent = fromNow;
    dataTime.textContent = time;
  }

  setClientSideValidations() {
    validateForms();
  }

  setTimeToggle() {
    const allDates = document.querySelectorAll('[data-post-date]');

    allDates.forEach(element => {
      const postDate = this.getDate(element);
      this.setDateAndTime(element, postDate, this.getTime(postDate));
    });
  }

  setCollapseItems() {
    const collapseLineItems = document.querySelector('#collapse-line-items');

    if (collapseLineItems) {
      collapseLineItems.addEventListener('click', event => {
        event.preventDefault();

        document.querySelector('#line-items').style.display = 'none';
        document.querySelector('#collapse-line-items').style.display = 'none';
        document.querySelector('#expand-line-items').style.display = 'block';
      });
    }
  }

  toggleSpinner(event) {
    const { target } = event;
    const spinner = target.parentNode.querySelector('.spinner');

    if (target && spinner) {
      target.classList.add('hide');
      spinner.classList.remove('hide');
    }
  }

  setSidebarDateCountdown() {
    const elements = document.querySelectorAll('.countdown-clock');

    if (elements.length < 1) {
      return;
    }

    const conventionStartDate = elements[0].dataset.countdownDate;

    const today = new Date();
    const todayEpoch = today.getTime();

    const target = new Date(conventionStartDate);
    const targetEpoch = target.getTime();

    const daysLeft = Math.floor(
      (targetEpoch - todayEpoch) / (60 * 60 * 24) / 1000,
    );

    elements.forEach(element => {
      if (daysLeft < 0) {
        element.textContent = 'Game On!';
      } else {
        element.textContent = `${daysLeft} Days`;
      }
    });
  }
}
