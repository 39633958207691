import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { isEmpty } from 'ramda';
import ScheduleRow from './ScheduleRow';
import Loading from '../search/EventFinder/Loading';
import { isEven } from './helpers/searchHelpers';

const ScheduleTable = ({
  schedules,
  loadMoreSchedules,
  hasMore,
  searchComplete,
}) => {
  return (
    <Fragment>
      <div className="schedule-header">
        <div>&#160;</div>
        <div>Title</div>
        <div>Time / Location</div>
        <div>Tickets</div>
      </div>
      <div className="schedule-items">
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreSchedules}
          loader={<Loading key={0} />}
          threshold={250}
          hasMore={hasMore}
        >
          {schedules.map((event, i) => (
            <ScheduleRow key={event.event_id} event={event} even={isEven(i)} />
          ))}
        </InfiniteScroll>
        {searchComplete && isEmpty(schedules) && (
          <div className="schedule-items">
            <div className="no-results">No results found for this search.</div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ScheduleTable;

ScheduleTable.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadMoreSchedules: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  searchComplete: PropTypes.bool.isRequired,
};
