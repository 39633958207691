import React, { useState, useEffect } from 'react';
import { equals } from 'ramda';
import PropTypes from 'prop-types';
import TicketRow from './TicketRow';
import TicketStats from './TicketStats';
import InfiniteScroll from 'react-infinite-scroll-component';

const TicketsTable = ({ tickets, renderStats, fetchNextPage, hasMore }) => {
  const [filter, setFilter] = useState(null);
  const [visibleTickets, setVisibleTickets] = useState([]);

  useEffect(() => {
    if (!equals(tickets, visibleTickets)) {
      setVisibleTickets(tickets);
    }
  }, [tickets, visibleTickets]);

  const groupTickets = (tickets) => {
    const contacts = [];
    tickets.forEach((ticket) => {
      const exist = contacts.find(obj => obj.contact_id === ticket.contact_id);
      if (exist) {
        exist.tickets.push(ticket);
        exist.status = getStatus(exist.tickets);
      } else {
        contacts.push({
          contact_id: ticket.contact_id,
          name: ticket.contact_name,
          pronoun: ticket.contact_pronoun,
          tickets: [ticket],
          pending: ticket.status === 'pending',
          status: ticket.redeemed ? "redeemed" : "unredeemed",
          error: ticket.error,
          failed: ticket.failed,
        });
      }
    });
    return contacts;
  };

  const filterRedeemed = (tickets) => {
    return tickets.filter((ticket) => ticket.redeemed);
  };

  const filterUnredeemed = (tickets) => {
    return tickets.filter((ticket) => !ticket.redeemed);
  };

  const getStatus = (tickets) => {
    let redeemedCount = filterRedeemed(tickets).length;
    let unredeemedCount = filterUnredeemed(tickets).length;
    if (redeemedCount === 0) {
      return "unredeemed";
    }
    if (unredeemedCount === 0) {
      return "redeemed";
    }
    if (redeemedCount > 0 && unredeemedCount > 0) {
      return "guests";
    }
  };

  const handleButtonClick = (newFilter) => {
    if (newFilter === filter) {
      setFilter(null);
    } else {
      setFilter(newFilter);
    }
  };

  const applyFilter = (contacts) => {
    if (filter) {
      if (filter === "redeemed") {
        const filtered = contacts.filter(contact => contact.status === filter || contact.status === "guests");
        return filtered;
      }
      const filtered = contacts.filter(contact => contact.status === filter);

      return filtered;
    }
    return contacts;
  };

  const contacts = groupTickets(tickets);
  const filteredContacts = applyFilter(contacts);

  const renderedContacts = filteredContacts.map((contact, i) => (
    <TicketRow key={i} contact={contact} even={i % 2 === 0} />
  ));

  return (
    <div className="tickets">
      {renderStats &&
        <TicketStats
          tickets={contacts}
          handleButtonClick={handleButtonClick}
          currentFilter={filter}
        />
      }
      <InfiniteScroll
        dataLength={visibleTickets.length}
        next={fetchNextPage}
        hasMore={hasMore}
      >
        <table className="records">
          <tbody>
            <tr>
              <th>Ticket Holder</th>
              <th className="text-right">Tickets / Status</th>
            </tr>
            {renderedContacts}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

TicketsTable.propTypes = {
  tickets: PropTypes.array.isRequired,
  renderStats: PropTypes.bool,
  fetchNextPage: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export default TicketsTable;
